"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Vector3LightPropertySettingComponent = void 0;
var BaseLightPropertySettingComponent_1 = require("./BaseLightPropertySettingComponent");
var Vector3LightPropertySettingComponent = /** @class */ (function (_super) {
    __extends(Vector3LightPropertySettingComponent, _super);
    // #region Constructors (1)
    function Vector3LightPropertySettingComponent(api, settingDefinition, parent, componentFactory, dragControls) {
        var _this = _super.call(this, api, settingDefinition, componentFactory.createShapeDiverSetting({
            id: settingDefinition.id,
            name: settingDefinition.name,
            type: "vector3" /* VECTOR3 */,
            value: settingDefinition.value,
            order: settingDefinition.order,
            groupId: settingDefinition.groupId,
            lightDef: settingDefinition.lightDef,
        }, parent), dragControls) || this;
        _this._component.parameterDefinition.update = function (v) {
            var lightDef = _this.getLightDef();
            lightDef.properties[_this.id].x = v.x;
            lightDef.properties[_this.id].y = v.y;
            lightDef.properties[_this.id].z = v.z;
            _this._api.scene.lights.updateLight(lightDef);
            _this._dragControls.updateLight(lightDef);
        };
        _this._dragControls.onLightUpdate(_this.getLightDef().id, function (data) {
            _this.value = data.properties[_this.id];
            return data;
        });
        return _this;
    }
    Object.defineProperty(Vector3LightPropertySettingComponent.prototype, "value", {
        // TODO: vector 3 as type
        get: function () {
            var comp = this.component.component;
            var x = comp.xInput.value;
            var y = comp.yInput.value;
            var z = comp.zInput.value;
            // TODO: vector 3 as type
            return { x: x, y: y, z: z };
        },
        // TODO: vector 3 as type
        set: function (v) {
            var vector = v;
            var comp = this.component.component;
            comp.xInput.value = vector.x.toString();
            comp.yInput.value = vector.y.toString();
            comp.zInput.value = vector.z.toString();
        },
        enumerable: false,
        configurable: true
    });
    return Vector3LightPropertySettingComponent;
}(BaseLightPropertySettingComponent_1.BaseLightPropertySettingComponent));
exports.Vector3LightPropertySettingComponent = Vector3LightPropertySettingComponent;
