"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseLightPropertySettingComponent = void 0;
var BaseSettingComponent_1 = require("../BaseSettingComponent");
/**
 * The abstract light property setting class, for all properties.
 */
var BaseLightPropertySettingComponent = /** @class */ (function (_super) {
    __extends(BaseLightPropertySettingComponent, _super);
    // #region Constructors (1)
    function BaseLightPropertySettingComponent(api, settingDefinition, component, _dragControls) {
        var _this = _super.call(this, api, settingDefinition, component) || this;
        _this._dragControls = _dragControls;
        return _this;
    }
    Object.defineProperty(BaseLightPropertySettingComponent.prototype, "component", {
        // #endregion Constructors (1)
        // #region Public Accessors (1)
        get: function () {
            return this._component;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BaseLightPropertySettingComponent.prototype, "lightId", {
        // #endregion Public Accessors (1)
        // #region Protected Accessors (1)
        get: function () {
            return this._component.id;
        },
        enumerable: false,
        configurable: true
    });
    // #endregion Protected Accessors (1)
    // #region Protected Methods (1)
    // public fireDragControlsUpdate()
    // {
    //     const lightDef = this.getLightDef();
    //     this._dragControls.updateLight(lightDef);
    // }
    BaseLightPropertySettingComponent.prototype.getLightDef = function () {
        return this._api.scene.lights.getLight(this._settingDefinition.lightDef.id).data;
    };
    return BaseLightPropertySettingComponent;
}(BaseSettingComponent_1.BaseSettingComponent));
exports.BaseLightPropertySettingComponent = BaseLightPropertySettingComponent;
