"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CameraLinearInterpolation = void 0;
var THREE = require("../../../../../../externals/three");
var CameraLinearInterpolation = /** @class */ (function () {
    // #region Constructors (1)
    function CameraLinearInterpolation(_cameraControls, _from, _to) {
        this._cameraControls = _cameraControls;
        this._from = _from;
        this._to = _to;
    }
    // #endregion Constructors (1)
    // #region Public Methods (3)
    CameraLinearInterpolation.prototype.onComplete = function (value) {
        var positionOffset = new THREE.Vector3(this._to.position.x, this._to.position.y, this._to.position.z).sub(this._cameraControls.position);
        this._cameraControls.applyPositionMatrix(new THREE.Matrix4().makeTranslation(positionOffset.x, positionOffset.y, positionOffset.z));
        var targetOffset = new THREE.Vector3(this._to.target.x, this._to.target.y, this._to.target.z).sub(this._cameraControls.target);
        this._cameraControls.applyTargetMatrix(new THREE.Matrix4().makeTranslation(targetOffset.x, targetOffset.y, targetOffset.z));
    };
    CameraLinearInterpolation.prototype.onStop = function (value) {
    };
    CameraLinearInterpolation.prototype.onUpdate = function (value) {
        var p = this._from.position.clone().multiplyScalar(1 - value.delta).add(this._to.position.clone().multiplyScalar(value.delta));
        var positionOffset = p.clone().sub(this._cameraControls.position);
        this._cameraControls.applyPositionMatrix(new THREE.Matrix4().makeTranslation(positionOffset.x, positionOffset.y, positionOffset.z));
        var t = this._from.target.clone().multiplyScalar(1 - value.delta).add(this._to.target.clone().multiplyScalar(value.delta));
        var targetOffset = t.clone().sub(this._cameraControls.target);
        this._cameraControls.applyTargetMatrix(new THREE.Matrix4().makeTranslation(targetOffset.x, targetOffset.y, targetOffset.z));
    };
    return CameraLinearInterpolation;
}());
exports.CameraLinearInterpolation = CameraLinearInterpolation;
