"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Node = void 0;
var THREE = require("../../../externals/three.js");
var Node = /** @class */ (function () {
    // #endregion Properties (8)
    // #region Constructors (1)
    function Node(id, gltf, parent, allNodes, rootNode) {
        var _a;
        if (parent === void 0) { parent = null; }
        if (rootNode === void 0) { rootNode = false; }
        this.id = id;
        this.gltf = gltf;
        this.parent = parent;
        this.allNodes = allNodes;
        this.rootNode = rootNode;
        this.nodes = [];
        this.transformationsOrder = [];
        this.materials = [];
        this.nodeRef = gltf.nodes[id];
        this.mesh = isNaN(this.nodeRef.mesh) ? null : gltf.meshes[this.nodeRef.mesh];
        this.camera = isNaN(this.nodeRef.camera) ? null : gltf.cameras[this.nodeRef.camera];
        this.matrix = new THREE.Matrix4();
        this.matrix.multiply(gltf.rotationMatrix);
        this.transformedMatrix = new THREE.Matrix4();
        if (this.nodeRef.matrix) {
            this.transformedMatrix.fromArray(this.nodeRef.matrix);
        }
        else {
            if (this.nodeRef.translation) {
                var m = new THREE.Matrix4();
                m.makeTranslation.apply(m, __spread(this.nodeRef.translation));
                this.transformedMatrix.multiply(m);
            }
            if (this.nodeRef.rotation) {
                var quaternion = new ((_a = THREE.Quaternion).bind.apply(_a, __spread([void 0], this.nodeRef.rotation)))();
                var m = new THREE.Matrix4();
                m.makeRotationFromQuaternion(quaternion);
                this.transformedMatrix.multiply(m);
            }
            if (this.nodeRef.scale) {
                var m = new THREE.Matrix4();
                m.makeScale.apply(m, __spread(this.nodeRef.scale));
                this.transformedMatrix.multiply(m);
            }
        }
        this.setTransformationOrder();
        this.createChildNodes(gltf, allNodes);
        this.resolveFinalMatrix();
        this.createMaterials(gltf);
        allNodes.push(this);
    }
    Object.defineProperty(Node.prototype, "name", {
        // #endregion Constructors (1)
        // #region Public Accessors (1)
        get: function () {
            return this.nodeRef.name;
        },
        set: function (val) {
            this.nodeRef.name = val;
        },
        enumerable: false,
        configurable: true
    });
    // #endregion Public Accessors (1)
    // #region Private Methods (4)
    Node.prototype.createChildNodes = function (gltf, allNodes) {
        var _this = this;
        if (this.nodeRef.children && this.nodeRef.children.length > 0) {
            this.nodeRef.children.forEach(function (childId) {
                return _this.nodes.push(new Node(childId, gltf, _this, allNodes));
            });
        }
    };
    Node.prototype.createMaterials = function (gltf) {
        var _this = this;
        if (this.mesh) {
            this.mesh.primitives.forEach(function (primitive) {
                if (primitive.hasOwnProperty('material')) {
                    _this.materials.push(gltf.materials[primitive.material]);
                }
            });
        }
    };
    Node.prototype.resolveFinalMatrix = function () {
        var mi = this.transformationsOrder[0];
        for (var i = 1; i < this.transformationsOrder.length; i++) {
            var m = this.transformationsOrder[i];
            mi.multiply(m);
        }
        this.matrix.multiply(mi);
    };
    Node.prototype.setTransformationOrder = function () {
        var parent = this.parent;
        this.transformationsOrder.push(this.transformedMatrix.clone());
        while (parent) {
            if (parent.transformedMatrix && this.transformationsOrder.length) {
                this.transformationsOrder.push(parent.transformedMatrix.clone());
            }
            parent = parent.parent;
        }
        this.transformationsOrder.reverse();
    };
    return Node;
}());
exports.Node = Node;
