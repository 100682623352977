"use strict";
var _a, _b;
Object.defineProperty(exports, "__esModule", { value: true });
exports.Accessor = void 0;
var gltf2schema_1 = require("../../../schema/gltf2schema");
var ELEM_IN_TYPE = (_a = {},
    _a[gltf2schema_1.GLTF2AccessorType.SCALAR] = 1,
    _a[gltf2schema_1.GLTF2AccessorType.VEC2] = 2,
    _a[gltf2schema_1.GLTF2AccessorType.VEC3] = 3,
    _a[gltf2schema_1.GLTF2AccessorType.VEC4] = 4,
    _a);
var SIZE_OF_ELEM_IN_BYTES = (_b = {},
    _b[gltf2schema_1.GLTF2ComponentType.UNSIGNED_BYTE] = 1,
    _b[gltf2schema_1.GLTF2ComponentType.UNSIGNED_SHORT] = 2,
    _b[gltf2schema_1.GLTF2ComponentType.UNSIGNED_INT] = 4,
    _b[gltf2schema_1.GLTF2ComponentType.FLOAT] = 4,
    _b);
var Accessor = /** @class */ (function () {
    // #endregion Properties (13)
    // #region Constructors (1)
    function Accessor(id, gltf, buffer, bufferView) {
        this.id = id;
        this.buffer = buffer;
        this.bufferView = bufferView;
        this.accessorRef = gltf.accessors[id];
        this.type = this.accessorRef.type;
        this.componentType = this.accessorRef.componentType;
        this.offsetForInterleaved = this.bufferView.numOfTypeArrayElementsPerVertex;
        this.byteOffset = this.accessorRef.byteOffset || 0;
        this.min = this.accessorRef.min;
        this.max = this.accessorRef.max;
        // total elements in structure, for example, vec3 has x,y,z therefore 3
        this.elementComponentsSize = ELEM_IN_TYPE[this.type];
        // size of each element. For example vec3 has floats, and each float is 4 sbytes
        this.bytesPerElement = SIZE_OF_ELEM_IN_BYTES[this.componentType];
        var isFloatArray = this.componentType === gltf2schema_1.GLTF2ComponentType.FLOAT;
        // count of elements, for example 24 vec3 elements is 24
        this.count = this.accessorRef.count;
        // total byte length, for example count of each vec3 24, count of each element in vec3 x,y,z and each element byte size 4
        // therefore 24 * 3 * 4
        this.byteLength = this.count * this.elementComponentsSize * this.bytesPerElement;
        var dataView = this.bufferView.dataView;
        this.bufferArray = null;
        var array = [];
        if (this.bufferView.isInterleaved) {
            if (this.bufferView.interleavedBuffer == null) {
                array = this.processInterleavedBufferArray(dataView, isFloatArray);
            }
        }
        else {
            array = this.processBufferArray(dataView, isFloatArray);
        }
        // final array
        switch (this.bytesPerElement) {
            case 1:
                this.bufferArray = new Uint8Array(array);
                break;
            case 2:
                this.bufferArray = new Uint16Array(array);
                break;
            case 4:
                if (isFloatArray) {
                    this.bufferArray = new Float32Array(array);
                }
                else {
                    this.bufferArray = new Uint32Array(array);
                }
                break;
        }
        this.array = array;
        if (this.bufferView.isInterleaved) {
            this.bufferView.interleavedBuffer = this.bufferArray;
        }
    }
    // #endregion Constructors (1)
    // #region Private Methods (2)
    Accessor.prototype.processBufferArray = function (dataView, isFloatArray) {
        var array = [];
        var k = 0;
        for (var i = this.byteOffset; i < this.byteOffset + this.byteLength; i += this.bytesPerElement) {
            var byte = 0;
            switch (this.bytesPerElement) {
                case 1:
                    byte = dataView.getUint8(i);
                    break;
                case 2:
                    byte = dataView.getUint16(i, true);
                    break;
                case 4:
                    if (isFloatArray) {
                        byte = dataView.getFloat32(i, true);
                        if (this.min) {
                            if (byte < this.min[k]) {
                                byte = this.min[k];
                            }
                        }
                        if (this.max) {
                            if (byte > this.max[k]) {
                                byte = this.max[k];
                            }
                        }
                        k++;
                        if (k >= this.elementComponentsSize) {
                            k = 0;
                        }
                    }
                    else {
                        byte = dataView.getUint32(i, true);
                    }
                    break;
            }
            array.push(byte);
        }
        return array;
    };
    Accessor.prototype.processInterleavedBufferArray = function (dataView, isFloatArray) {
        var array = [];
        var k = 0;
        for (var i = 0; i < this.bufferView.byteLength; i += this.bytesPerElement) {
            var byte = 0;
            switch (this.bytesPerElement) {
                case 1:
                    byte = dataView.getUint8(i);
                    break;
                case 2:
                    byte = dataView.getUint16(i, true);
                    break;
                case 4:
                    if (isFloatArray) {
                        byte = dataView.getFloat32(i, true);
                        if (this.min) {
                            if (byte < this.min[k]) {
                                byte = this.min[k];
                            }
                        }
                        if (this.max) {
                            if (byte > this.max[k]) {
                                byte = this.max[k];
                            }
                        }
                        k++;
                        if (k >= this.elementComponentsSize) {
                            k = 0;
                        }
                    }
                    else {
                        byte = dataView.getUint32(i, true);
                    }
                    break;
            }
            array.push(byte);
        }
        return array;
    };
    return Accessor;
}());
exports.Accessor = Accessor;
