"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultControlParameterType = void 0;
var DefaultControlParameterType;
(function (DefaultControlParameterType) {
    DefaultControlParameterType["STRING"] = "string";
    DefaultControlParameterType["CHECKBOX"] = "checkbox";
    DefaultControlParameterType["VECTOR3"] = "vector3";
    DefaultControlParameterType["BUTTON"] = "button";
    DefaultControlParameterType["CHECKLIST"] = "checklist";
    DefaultControlParameterType["GROUP"] = "group";
    DefaultControlParameterType["COLOR"] = "color";
    DefaultControlParameterType["DROPDOWN"] = "dropdown";
    DefaultControlParameterType["FILE"] = "file";
    DefaultControlParameterType["MODAL"] = "modal";
    DefaultControlParameterType["SLIDER"] = "slider";
    DefaultControlParameterType["STATICHTML"] = "statichtml";
})(DefaultControlParameterType = exports.DefaultControlParameterType || (exports.DefaultControlParameterType = {}));
