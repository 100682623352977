"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SnapPoint = void 0;
/** @ignore */
var THREE = require("../../../externals/three");
var SnapPoint = /** @class */ (function () {
    function SnapPoint(definition) {
        this._id = definition.id;
        this._snapRadius = definition.snapRadius;
        this.orientation = definition.orientation;
        this.cross = definition.orientation.direction.clone().cross(definition.orientation.axis);
        this._rotationMatrix = new THREE.Matrix4().makeBasis(definition.orientation.direction.clone().cross(definition.orientation.axis), definition.orientation.direction, definition.orientation.axis);
        this._rotatedStandardDirection = new THREE.Vector3(0, 1, 0).applyMatrix4(this._rotationMatrix);
        this._anchorElements = definition.anchorElements;
        this._position = definition.position;
    }
    SnapPoint.prototype.snap = function (position) {
        var distance = this._position.distanceTo(position);
        var dir = position.clone().sub(this._position).normalize();
        return {
            snapElement: this,
            distance: distance,
            position: this._position.clone(),
            dot: dir.dot(this._rotatedStandardDirection),
            snap: distance < this._snapRadius
        };
    };
    Object.defineProperty(SnapPoint.prototype, "id", {
        get: function () {
            return this._id;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SnapPoint.prototype, "rotationMatrix", {
        get: function () {
            return this._rotationMatrix;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SnapPoint.prototype, "anchorIds", {
        get: function () {
            return this._anchorElements;
        },
        enumerable: false,
        configurable: true
    });
    return SnapPoint;
}());
exports.SnapPoint = SnapPoint;
