"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CameraSphericalInterpolation = void 0;
var THREE = require("../../../../../../externals/three");
var CameraSphericalInterpolation = /** @class */ (function () {
    // #endregion Properties (6)
    // #region Constructors (1)
    function CameraSphericalInterpolation(_cameraControls, _from, _to) {
        this._cameraControls = _cameraControls;
        this._from = _from;
        this._to = _to;
        this._radius_from = this._from.position.distanceTo(this._from.target);
        this._direction_from = this._from.position.clone().sub(this._from.target).normalize();
        this._radius_to = this._to.position.distanceTo(this._to.target);
        this._direction_to = this._to.position.clone().sub(this._to.target).normalize();
        this._axis = this._direction_to.clone().cross(this._direction_from).normalize();
        this._c_angle = -Math.acos(this._direction_to.dot(this._direction_from));
    }
    // #endregion Constructors (1)
    // #region Public Methods (3)
    CameraSphericalInterpolation.prototype.onComplete = function (value) {
        var positionOffset = new THREE.Vector3(this._to.position.x, this._to.position.y, this._to.position.z).sub(this._cameraControls.position);
        this._cameraControls.applyPositionMatrix(new THREE.Matrix4().makeTranslation(positionOffset.x, positionOffset.y, positionOffset.z));
        var targetOffset = new THREE.Vector3(this._to.target.x, this._to.target.y, this._to.target.z).sub(this._cameraControls.target);
        this._cameraControls.applyTargetMatrix(new THREE.Matrix4().makeTranslation(targetOffset.x, targetOffset.y, targetOffset.z));
    };
    CameraSphericalInterpolation.prototype.onStop = function (value) {
    };
    CameraSphericalInterpolation.prototype.onUpdate = function (value) {
        var t = this._from.target.clone().multiplyScalar(1 - value.delta).add(this._to.target.clone().multiplyScalar(value.delta));
        var targetOffset = t.clone().sub(this._cameraControls.target);
        this._cameraControls.applyTargetMatrix(new THREE.Matrix4().makeTranslation(targetOffset.x, targetOffset.y, targetOffset.z));
        var angle = this._c_angle * value.delta;
        var dir = this._direction_from.clone().applyAxisAngle(this._axis, angle).normalize();
        var p = t.clone().add(dir.clone().multiplyScalar((this._radius_from * (1 - value.delta) + this._radius_to * value.delta)));
        var positionOffset = p.clone().sub(this._cameraControls.position);
        this._cameraControls.applyPositionMatrix(new THREE.Matrix4().makeTranslation(positionOffset.x, positionOffset.y, positionOffset.z));
    };
    return CameraSphericalInterpolation;
}());
exports.CameraSphericalInterpolation = CameraSphericalInterpolation;
