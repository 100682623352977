"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrbitControlsLogic = void 0;
var THREE = require("../../../../../../externals/three");
var OrbitControlsLogic = /** @class */ (function () {
    // #endregion Properties (15)
    // #region Constructors (1)
    function OrbitControlsLogic(_settings, _controls) {
        var _this = this;
        this._settings = _settings;
        this._controls = _controls;
        this._damping = {
            rotation: {
                time: 0,
                duration: 0,
                theta: 0,
                phi: 0
            },
            zoom: {
                time: 0,
                duration: 0,
                delta: 0
            },
            pan: {
                time: 0,
                duration: 0,
                offset: new THREE.Vector3()
            },
        };
        this._dollyDelta = 0;
        this._dollyEnd = 0;
        this._dollyStart = 0;
        this._panDelta = new THREE.Vector2();
        this._panEnd = new THREE.Vector2();
        this._panStart = new THREE.Vector2();
        this._rotateDelta = new THREE.Vector2();
        this._rotateEnd = new THREE.Vector2();
        this._rotateStart = new THREE.Vector2();
        this._settingsAdjustements = {
            autoRotationSpeed: 2 * Math.PI / 60 / 60,
            damping: 1.0,
            movementSmoothness: 1.0,
            panSpeed: 2.0,
            rotationSpeed: Math.PI,
            zoomSpeed: 0.025,
        };
        this._touchAdjustements = {
            autoRotationSpeed: 1.0,
            damping: 1.0,
            movementSmoothness: 1.0,
            panSpeed: 1.5,
            rotationSpeed: 2.0,
            zoomSpeed: 100.0,
        };
        this._quat = new THREE.Quaternion().setFromUnitVectors(new THREE.Vector3(0, 0, 1), new THREE.Vector3(0, 1, 0));
        this._quatInverse = this._quat.clone().inverse();
        this._adjustedSettings = {
            autoRotationSpeed: this._settings.getSetting('autoRotationSpeed') * this._settingsAdjustements.autoRotationSpeed,
            damping: this._settings.getSetting('damping') * this._settingsAdjustements.damping,
            movementSmoothness: this._settings.getSetting('movementSmoothness') * this._settingsAdjustements.movementSmoothness,
            panSpeed: this._settings.getSetting('panSpeed') * this._settingsAdjustements.panSpeed,
            rotationSpeed: this._settings.getSetting('rotationSpeed') * this._settingsAdjustements.rotationSpeed,
            zoomSpeed: this._settings.getSetting('zoomSpeed') * this._settingsAdjustements.zoomSpeed,
        };
        Object.keys(this._adjustedSettings).forEach(function (element) {
            _this._settings.registerHook(element, function (v) {
                _this._adjustedSettings[element] = v * _this._settingsAdjustements[element];
                return true;
            });
        });
    }
    // #endregion Constructors (1)
    // #region Public Methods (7)
    OrbitControlsLogic.prototype.isWithinRestrictions = function (position, target) {
        var pCubeSetting = this._settings.getSetting('restrictions.position.cube'), pSphereSetting = this._settings.getSetting('restrictions.position.sphere');
        var pBox = new THREE.Box3(new THREE.Vector3(pCubeSetting.min.x, pCubeSetting.min.y, pCubeSetting.min.z), new THREE.Vector3(pCubeSetting.max.x, pCubeSetting.max.y, pCubeSetting.max.z)), pSphere = new THREE.Sphere(new THREE.Vector3(pSphereSetting.center.x, pSphereSetting.center.y, pSphereSetting.center.z), pSphereSetting.radius);
        var tCubeSetting = this._settings.getSetting('restrictions.target.cube'), tSphereSetting = this._settings.getSetting('restrictions.target.sphere');
        var tBox = new THREE.Box3(new THREE.Vector3(tCubeSetting.min.x, tCubeSetting.min.y, tCubeSetting.min.z), new THREE.Vector3(tCubeSetting.max.x, tCubeSetting.max.y, tCubeSetting.max.z)), tSphere = new THREE.Sphere(new THREE.Vector3(tSphereSetting.center.x, tSphereSetting.center.y, tSphereSetting.center.z), tSphereSetting.radius);
        if (!(pBox.containsPoint(position) && pSphere.containsPoint(position)))
            return false;
        if (!(tBox.containsPoint(target) && tSphere.containsPoint(target)))
            return false;
        var currentDistance = position.distanceTo(target);
        if (currentDistance > this._settings.getSetting('restrictions.zoom.maxDistance') || currentDistance < this._settings.getSetting('restrictions.zoom.minDistance'))
            return false;
        var minPolarAngle = this._settings.getSetting('restrictions.rotation.minPolarAngle') * (Math.PI / 180), maxPolarAngle = this._settings.getSetting('restrictions.rotation.maxPolarAngle') * (Math.PI / 180), minAzimuthAngle = this._settings.getSetting('restrictions.rotation.minAzimuthAngle') * (Math.PI / 180), maxAzimuthAngle = this._settings.getSetting('restrictions.rotation.maxAzimuthAngle') * (Math.PI / 180);
        if (minAzimuthAngle !== -Infinity ||
            maxAzimuthAngle !== Infinity ||
            minPolarAngle !== 0 ||
            maxPolarAngle !== 180) {
            var offset = new THREE.Vector3();
            offset.copy(position).sub(target);
            offset.applyQuaternion(this._quat);
            var spherical = new THREE.Spherical().setFromVector3(offset);
            if (spherical.theta < minAzimuthAngle ||
                spherical.theta > maxAzimuthAngle ||
                spherical.phi < minPolarAngle ||
                spherical.phi > maxPolarAngle) {
                return false;
            }
        }
        return true;
    };
    OrbitControlsLogic.prototype.pan = function (x, y, active, touch) {
        if (touch) {
            x = x / window.devicePixelRatio;
            y = y / window.devicePixelRatio;
        }
        if (!active) {
            this._panStart.set(x, y);
        }
        else {
            this._panEnd.set(x, y);
            this._panDelta.subVectors(this._panEnd, this._panStart);
            if (this._panDelta.x === 0 && this._panDelta.y === 0)
                return;
            this._panStart.copy(this._panEnd);
            var offset = this.panDeltaToOffset(this._panDelta.multiplyScalar(this._adjustedSettings.panSpeed * (touch ? this._touchAdjustements.panSpeed : 1.0)));
            if (this._damping.pan.duration > 0) {
                if (offset.x < 0) {
                    offset.x = Math.min(offset.x, this._adjustedSettings.movementSmoothness * this._damping.pan.offset.x);
                }
                else {
                    offset.x = Math.max(offset.x, this._adjustedSettings.movementSmoothness * this._damping.pan.offset.x);
                }
                if (offset.y < 0) {
                    offset.y = Math.min(offset.y, this._adjustedSettings.movementSmoothness * this._damping.pan.offset.y);
                }
                else {
                    offset.y = Math.max(offset.y, this._adjustedSettings.movementSmoothness * this._damping.pan.offset.y);
                }
                if (offset.z < 0) {
                    offset.z = Math.min(offset.z, this._adjustedSettings.movementSmoothness * this._damping.pan.offset.z);
                }
                else {
                    offset.z = Math.max(offset.z, this._adjustedSettings.movementSmoothness * this._damping.pan.offset.z);
                }
            }
            var damping = 1 - Math.max(0.01, Math.min(0.99, this._adjustedSettings.damping));
            var framesOffsetX = (Math.log(1 / Math.abs(offset.x)) - 5 * Math.log(10)) / (Math.log(damping));
            var framesOffsetY = (Math.log(1 / Math.abs(offset.y)) - 5 * Math.log(10)) / (Math.log(damping));
            var framesOffsetZ = (Math.log(1 / Math.abs(offset.z)) - 5 * Math.log(10)) / (Math.log(damping));
            this._damping.pan.time = 0;
            this._damping.pan.duration = Math.max(framesOffsetX, Math.max(framesOffsetY, framesOffsetZ)) * 16.6666;
            this._damping.pan.offset = offset.clone();
            this._damping.rotation.duration = 0;
            this._damping.zoom.duration = 0;
            this._controls.applyTargetMatrix(new THREE.Matrix4().makeTranslation(offset.x, offset.y, offset.z), true);
            this._controls.applyPositionMatrix(new THREE.Matrix4().makeTranslation(offset.x, offset.y, offset.z), true);
        }
    };
    OrbitControlsLogic.prototype.reset = function () {
        this._damping = {
            rotation: {
                time: 0,
                duration: 0,
                theta: 0,
                phi: 0
            },
            zoom: {
                time: 0,
                duration: 0,
                delta: 0
            },
            pan: {
                time: 0,
                duration: 0,
                offset: new THREE.Vector3()
            },
        };
        this._dollyDelta = 0;
        this._dollyEnd = 0;
        this._dollyStart = 0;
        this._panDelta = new THREE.Vector2();
        this._panEnd = new THREE.Vector2();
        this._panStart = new THREE.Vector2();
        this._rotateDelta = new THREE.Vector2();
        this._rotateEnd = new THREE.Vector2();
        this._rotateStart = new THREE.Vector2();
    };
    OrbitControlsLogic.prototype.restrict = function (position, target) {
        // cube and sphere position restrictions
        var pCubeSetting = this._settings.getSetting('restrictions.position.cube'), pSphereSetting = this._settings.getSetting('restrictions.position.sphere');
        var pBox = new THREE.Box3(new THREE.Vector3(pCubeSetting.min.x, pCubeSetting.min.y, pCubeSetting.min.z), new THREE.Vector3(pCubeSetting.max.x, pCubeSetting.max.y, pCubeSetting.max.z)), pSphere = new THREE.Sphere(new THREE.Vector3(pSphereSetting.center.x, pSphereSetting.center.y, pSphereSetting.center.z), pSphereSetting.radius);
        if (!pBox.containsPoint(position))
            pBox.clampPoint(position, position);
        if (!pSphere.containsPoint(position))
            pSphere.clampPoint(position, position);
        // cube and sphere target restrictions
        var tCubeSetting = this._settings.getSetting('restrictions.target.cube'), tSphereSetting = this._settings.getSetting('restrictions.target.sphere');
        var tBox = new THREE.Box3(new THREE.Vector3(tCubeSetting.min.x, tCubeSetting.min.y, tCubeSetting.min.z), new THREE.Vector3(tCubeSetting.max.x, tCubeSetting.max.y, tCubeSetting.max.z)), tSphere = new THREE.Sphere(new THREE.Vector3(tSphereSetting.center.x, tSphereSetting.center.y, tSphereSetting.center.z), tSphereSetting.radius);
        if (!tBox.containsPoint(target))
            tBox.clampPoint(target, target);
        if (!tSphere.containsPoint(target))
            tSphere.clampPoint(target, target);
        // zoom restrictions
        var currentDistance = position.distanceTo(target);
        if (currentDistance > this._settings.getSetting('restrictions.zoom.maxDistance') || currentDistance < this._settings.getSetting('restrictions.zoom.minDistance')) {
            var direction = new THREE.Vector3();
            direction.copy(position).sub(target).normalize();
            var distance = Math.max(this._settings.getSetting('restrictions.zoom.minDistance'), Math.min(this._settings.getSetting('restrictions.zoom.maxDistance'), currentDistance));
            position = target.clone().add(direction.multiplyScalar(distance));
        }
        // angle restricitions
        var minPolarAngle = this._settings.getSetting('restrictions.rotation.minPolarAngle') * (Math.PI / 180), maxPolarAngle = this._settings.getSetting('restrictions.rotation.maxPolarAngle') * (Math.PI / 180), minAzimuthAngle = this._settings.getSetting('restrictions.rotation.minAzimuthAngle') * (Math.PI / 180), maxAzimuthAngle = this._settings.getSetting('restrictions.rotation.maxAzimuthAngle') * (Math.PI / 180);
        if (minAzimuthAngle !== -Infinity ||
            maxAzimuthAngle !== Infinity ||
            minPolarAngle !== 0 ||
            maxPolarAngle !== 180) {
            var offset = new THREE.Vector3();
            offset.copy(position).sub(target);
            offset.applyQuaternion(this._quat);
            var spherical = new THREE.Spherical().setFromVector3(offset);
            if (spherical.theta < minAzimuthAngle ||
                spherical.theta > maxAzimuthAngle ||
                spherical.phi < minPolarAngle ||
                spherical.phi > maxPolarAngle) {
                spherical.theta = Math.max(minAzimuthAngle, Math.min(maxAzimuthAngle, spherical.theta));
                spherical.phi = Math.max(minPolarAngle, Math.min(maxPolarAngle, spherical.phi));
                spherical.makeSafe();
                offset.setFromSpherical(spherical);
                offset.applyQuaternion(this._quatInverse);
                position = offset.add(target);
            }
        }
        return {
            position: position,
            target: target
        };
    };
    OrbitControlsLogic.prototype.rotate = function (x, y, active, touch) {
        if (touch) {
            x = x / window.devicePixelRatio;
            y = y / window.devicePixelRatio;
        }
        if (!active) {
            this._rotateStart.set(x, y);
        }
        else {
            this._rotateEnd.set(x, y);
            this._rotateDelta.subVectors(this._rotateEnd, this._rotateStart);
            this._rotateStart.copy(this._rotateEnd);
            if (this._controls.domElement.clientWidth == 0 || this._controls.domElement.clientHeight == 0)
                return;
            var spherical = new THREE.Spherical();
            var rotationSpeed = this._adjustedSettings.rotationSpeed * (touch ? this._touchAdjustements.rotationSpeed : 1.0);
            spherical.theta -= rotationSpeed * this._rotateDelta.x;
            spherical.phi -= rotationSpeed * this._rotateDelta.y;
            if (this._damping.rotation.duration > 0) {
                var thetaDelta = this._damping.rotation.theta - spherical.theta;
                spherical.theta += thetaDelta * this._adjustedSettings.movementSmoothness;
                var phiDelta = this._damping.rotation.phi - spherical.phi;
                spherical.phi += phiDelta * this._adjustedSettings.movementSmoothness;
            }
            var offset = this.rotationSphericalToOffset(spherical);
            var damping = 1 - Math.max(0.01, Math.min(1, this._adjustedSettings.damping));
            var framesTheta = (Math.log(1 / Math.abs(spherical.theta)) - 5 * Math.log(10)) / (Math.log(damping));
            var framesPhi = (Math.log(1 / Math.abs(spherical.phi)) - 5 * Math.log(10)) / (Math.log(damping));
            this._damping.rotation.time = 0;
            this._damping.rotation.duration = Math.max(framesTheta, framesPhi) * 16.6666;
            this._damping.rotation.theta = spherical.theta;
            this._damping.rotation.phi = spherical.phi;
            this._damping.pan.duration = 0;
            this._damping.zoom.duration = 0;
            this._controls.applyPositionMatrix(new THREE.Matrix4().makeTranslation(offset.x, offset.y, offset.z), true);
        }
    };
    OrbitControlsLogic.prototype.update = function (time, manualInteraction) {
        if (manualInteraction === true) {
            this._damping.zoom.duration = 0;
            this._damping.pan.duration = 0;
            this._damping.rotation.duration = 0;
        }
        var damping = 1 - Math.max(0.01, Math.min(1, this._adjustedSettings.damping));
        if (this._damping.pan.duration > 0) {
            if (this._damping.pan.time + time > this._damping.pan.duration) {
                this._damping.pan.time = this._damping.pan.duration;
                this._damping.pan.duration = 0;
            }
            else {
                this._damping.pan.time += time;
                var frameSinceStart = this._damping.pan.time / 16.6666;
                var offset = this._damping.pan.offset.clone().multiplyScalar(Math.pow(damping, frameSinceStart));
                this._controls.applyTargetMatrix(new THREE.Matrix4().makeTranslation(offset.x, offset.y, offset.z));
                this._controls.applyPositionMatrix(new THREE.Matrix4().makeTranslation(offset.x, offset.y, offset.z));
            }
        }
        else {
            this._damping.pan.time = 0;
        }
        if (this._damping.rotation.duration > 0) {
            if (this._damping.rotation.time + time > this._damping.rotation.duration) {
                this._damping.rotation.time = this._damping.rotation.duration;
                this._damping.rotation.duration = 0;
            }
            else {
                this._damping.rotation.time += time;
                var frameSinceStart = this._damping.rotation.time / 16.6666;
                var spherical = new THREE.Spherical();
                spherical.theta = this._damping.rotation.theta * Math.pow(damping, frameSinceStart);
                spherical.phi = this._damping.rotation.phi * Math.pow(damping, frameSinceStart);
                var offset = this.rotationSphericalToOffset(spherical);
                this._controls.applyPositionMatrix(new THREE.Matrix4().makeTranslation(offset.x, offset.y, offset.z));
            }
        }
        else {
            this._damping.rotation.time = 0;
        }
        if (this._damping.zoom.duration > 0) {
            if (this._damping.zoom.time + time > this._damping.zoom.duration) {
                this._damping.zoom.time = this._damping.zoom.duration;
                this._damping.zoom.duration = 0;
            }
            else {
                this._damping.zoom.time += time;
                var frameSinceStart = this._damping.zoom.time / 16.6666;
                var delta = this._damping.zoom.delta * Math.pow(damping, frameSinceStart);
                var offset = this.zoomDistanceToOffset(delta);
                this._controls.applyPositionMatrix(new THREE.Matrix4().makeTranslation(offset.x, offset.y, offset.z));
            }
        }
        else {
            this._damping.zoom.time = 0;
        }
        if (this._settings.getSetting('enableAutoRotation')) {
            var spherical = new THREE.Spherical(1.0, 0.0, -this._adjustedSettings.autoRotationSpeed);
            var offset = this.rotationSphericalToOffset(spherical);
            this._controls.applyPositionMatrix(new THREE.Matrix4().makeTranslation(offset.x, offset.y, offset.z));
        }
    };
    OrbitControlsLogic.prototype.zoom = function (x, y, active, touch) {
        var distance = Math.sqrt(x * x + y * y);
        if (touch)
            distance = distance / window.devicePixelRatio;
        if (!active) {
            this._dollyStart = distance;
        }
        else {
            this._dollyEnd = distance;
            this._dollyDelta = this._dollyEnd - this._dollyStart;
            this._dollyStart = this._dollyEnd;
            if (this._damping.zoom.duration > 0) {
                if (this._dollyDelta < 0) {
                    this._dollyDelta = Math.min(this._dollyDelta, this._adjustedSettings.movementSmoothness * this._damping.zoom.delta);
                }
                else {
                    this._dollyDelta = Math.max(this._dollyDelta, this._adjustedSettings.movementSmoothness * this._damping.zoom.delta);
                }
            }
            var delta = -this._dollyDelta * this._adjustedSettings.zoomSpeed * (touch ? this._touchAdjustements.zoomSpeed : 1.0);
            var damping = 1 - Math.max(0.01, Math.min(1, this._adjustedSettings.damping));
            var framesDelta = (Math.log(1 / Math.abs(this._dollyDelta)) - 5 * Math.log(10)) / (Math.log(damping));
            this._damping.zoom.time = 0;
            this._damping.zoom.duration = framesDelta * 16.6666;
            this._damping.zoom.delta = delta;
            this._damping.rotation.duration = 0;
            this._damping.pan.duration = 0;
            var offset = this.zoomDistanceToOffset(delta);
            this._controls.applyPositionMatrix(new THREE.Matrix4().makeTranslation(offset.x, offset.y, offset.z), true);
        }
    };
    // #endregion Public Methods (7)
    // #region Private Methods (3)
    OrbitControlsLogic.prototype.panDeltaToOffset = function (panDelta) {
        var offset = new THREE.Vector3();
        var panOffset = new THREE.Vector3();
        // perspective
        offset.copy(this._controls.getPositionWithManualUpdates()).sub(this._controls.getTargetWithManualUpdates());
        var targetDistance = offset.length();
        // half of the fov is center to top of screen
        targetDistance *= Math.tan(((this._controls.camera.fov / 2) * Math.PI) / 180.0);
        // we use only clientHeight here so aspect ratio does not distort speed
        // left
        var v1 = new THREE.Vector3();
        v1.setFromMatrixColumn(this._controls.camera.matrix, 0); // get X column of objectMatrix
        v1.multiplyScalar(-(2 * panDelta.x * targetDistance));
        panOffset.add(v1);
        // up
        var v = new THREE.Vector3();
        v.setFromMatrixColumn(this._controls.camera.matrix, 1); // get Y column of objectMatrix
        v.multiplyScalar((2 * panDelta.y * targetDistance));
        panOffset.add(v);
        return panOffset.clone();
    };
    OrbitControlsLogic.prototype.rotationSphericalToOffset = function (s) {
        var offset = new THREE.Vector3();
        offset.copy(this._controls.getPositionWithManualUpdates()).sub(this._controls.getTargetWithManualUpdates());
        offset.applyQuaternion(this._quat);
        var spherical = new THREE.Spherical().setFromVector3(offset);
        spherical.theta += s.theta;
        spherical.phi += s.phi;
        var minAzimuthAngle = this._settings.getSetting('restrictions.rotation.minAzimuthAngle') * (Math.PI / 180), maxAzimuthAngle = this._settings.getSetting('restrictions.rotation.maxAzimuthAngle') * (Math.PI / 180);
        if (spherical.theta > Math.PI) {
            spherical.theta -= 2 * Math.PI;
            if (minAzimuthAngle > spherical.theta) {
                spherical.theta += 2 * Math.PI;
            }
        }
        else if (spherical.theta < -Math.PI) {
            spherical.theta += 2 * Math.PI;
            if (maxAzimuthAngle < spherical.theta) {
                spherical.theta -= 2 * Math.PI;
            }
        }
        spherical.makeSafe();
        offset.setFromSpherical(spherical);
        offset.applyQuaternion(this._quatInverse);
        offset.add(this._controls.getTargetWithManualUpdates());
        offset.sub(this._controls.getPositionWithManualUpdates());
        return offset.clone();
    };
    OrbitControlsLogic.prototype.zoomDistanceToOffset = function (distance) {
        var offset = new THREE.Vector3();
        offset.copy(this._controls.getPositionWithManualUpdates()).sub(this._controls.getTargetWithManualUpdates());
        return offset.clone().multiplyScalar(distance);
    };
    return OrbitControlsLogic;
}());
exports.OrbitControlsLogic = OrbitControlsLogic;
;
