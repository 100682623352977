/**
 * @file Creates a shared interface for all ThreeDManagers.
 *       The ThreeDManagerInterface must be implemented by all ThreeDManagers.
 *       All described methods must be implemented.
 * 
 * @module ThreeDManagerInterface
 * @author Michael Oppitz
 */

/**
 * Interface for the ThreeDManager.
 * The ThreeDManager implements all functionality related to 3D.
 * 
 * //@interface ThreeDManagerInterface
 */
class ThreeDManagerInterface {

  constructor(___settings) {
    /**
     * The rendering handler.
     * 
     * @type {module:RenderingHandlerInterface~RenderingHandlerInterface}
     */
    this.renderingHandler = null;

    /**
     * The camera handler.
     * 
     * @type {module:CameraHandlerInterface~CameraHandlerInterface}
     */
    this.cameraHandler = null;

    /**
     * The material handler.
     * 
     * @type {module:MaterialHandlerInterface~MaterialHandlerInterface}
     */
    this.materialHandler = null;

    /**
     * The light handler.
     * 
     * @type {module:LightHandlerInterface~LightHandlerInterface}
     */
    this.lightHandler = null;

    /**
     * Flag if the manager was created successful.
     * 
     * @type {Boolean}
     */
    this.success = true;

    ////////////
    ////////////
    //
    // Global prototype
    //
    ////////////
    ////////////

    require('../../shared/mixins/GlobalMixin').call(this);

    ////////////
    ////////////
    //
    // Logging
    //
    // register standard logging functions,
    // and replace default logging to console by pub/sub scheme
    //
    ////////////
    ////////////

    require('../../shared/util/GlobalUtils').inject(___settings.loggingHandler, this);

    ////////////
    ////////////
    //
    // General messaging via pub/sub
    //
    ////////////
    ////////////

    require('../../shared/util/GlobalUtils').inject(___settings.messagingHandler, this);
  }

  /**
   * Initialization function that is called when it is decided to start the viewport.
   * The bounding box that is provided is the first scene bounding box so that all scene geometry can be adjusted evenly.
   * 
   * @abstract
   * @param {THREE.Box3} bb The initial scene bounding box
   */
  init(bb) { }

  /**
   * Updates all elements in the scene to the new size of the scene.
   * @abstract
   */
  adjustScene() { }

  /**
   * Computes the bounding box of the provided scene paths or the complete scene bounding box.
   * 
   * @abstract
   * @param {module:ApiInterfaceV2~ApiInterfaceV2#ScenePathType[]} scenePaths
   * @returns {THREE.Box3} the bounding box
   */
  computeBoundingBox(scenePaths) { }




  /**
   * Pauses the viewport.
   * @abstract
   */
  pause() { }

  /**
   * Resumes the viewport.
   * @abstract
   */
  resume() { }

  /**
   * Destroys the current viewport.
   * This api will be invalid after this operation.
   * @abstract
   */
  destroy() { }

  /**
   * Reloads this viewport.
   * @abstract
   */
  reload() { }




  /**
   * Adds a mesh and the corresponding material to the current viewport.
   * The material for this mesh has to be applied before every render call and remove after.
   * 
   * @abstract
   * @param {THREE.Mesh} mesh The mesh 
   * @param {THREE.Material} material The material as a subclass of {@link THREE.Material}
   */
  addMesh(mesh, material, properties) { }

  /**
   * Removes a mesh from the meshes of this viewport.
   * 
   * @abstract
   * @param {THREE.Mesh} mesh The mesh 
   */
  removeMesh(mesh) { }

  /**
   * Adds an anchor object to the scene.
   * 
   * @abstract
   * @param {THREE.Object3D} object 
   * @param {*} properties 
   */
  addAnchor(object, properties) { }

  /**
   * Removes an anchor object from the scene.
   * 
   * @abstract
   * @param {THREE.Object3D} object 
   */
  removeAnchor(object) { }





  /**
   * Fade in all geometry under a given path
   *
   * This assumes that the geometry under the given path has been set to be
   * fully transparent.
   * 
   * @abstract
   * @param {String} path Path of the geometry to be faded in
   * @param {Number} duration Duration of fade in in milliseconds
   * @return {Promise<Boolean>} Resolves once the effect is finished.
   */
  fadeIn(path, duration) { }

  /**
   * Fade out all geometry under a given path
   * This is a temporary effect which will be overwritten by any geometry reset.
   *
   * @abstract
   * @param {String} path Path of the geometry to be faded out
   * @param {Number} duration Duration of fade out in milliseconds
   * @return {Promise<Boolean>} Resolves once the effect is finished.
   */
  fadeOut(path, duration) { }




  /**
   * Sets a live transformation.
   * @see module:ApiInterfaceV2~ApiSceneInterface#setLiveTransformation
   * 
   * @abstract
   * @param {TWEEN.Group} group 
   * @param {module:ApiInterfaceV2~ApiInterfaceV2#ScenePathType[]} scenePaths 
   * @param {module:ApiInterfaceV2~ApiInterfaceV2#LiveTransformation[]} transformations 
   * @param {Boolean} reset 
   * @param {Number} duration 
   */
  setLiveTransformation(group, scenePaths, transformations, reset, duration) {  }




  /**
   * Update the interaction attributes for a path according to given preferences
   *
   * @abstract
   * @param {String} path The path for which to update settings
   * @param {Object} options Settings
   * @param {String} [options.interactionGroup]  name of the interaction group the path should be assigned to
   * @param {String} [options.interactionMode='sub']  mode of interaction: 'global' - all objects below path at once, 'sub' - individual objects below path
   * @param {String} [options.dragPlaneNormal]
   * @param {String} [options.dragPlane]
   * @param {Point3D} [options.dragOffset]
   */
  updateInteractions(path, options) { }

  /**
   * Removes a path from all interactions.
   * It will be deactivated from all current interactions and can't be activated until it is readded.
   * 
   * @abstract
   * @param {String} path The path which will be removed
   */
  removeFromInteractions(path) { }




  /**
   * Show or hide geometry based on its scene path.
   *
   * @param {module:ApiInterfaceV2~ApiInterfaceV2#ScenePathType[]} [show] - Optional list of scene paths of objects which should be shown
   * @param {module:ApiInterfaceV2~ApiInterfaceV2#ScenePathType[]} [hide] - Optional list of scene paths of objects which should be hidden
   * @return {module:ApiInterfaceV2~ApiInterfaceV2#APIResponse} APIResponse with a Boolean data, indicating whether the operation succeeded
   */
  toggleGeometry() { }

}
module.exports = ThreeDManagerInterface;