"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GroupSettings = void 0;
var interfaces_1 = require("../../../../interfaces/api/apps/sd-controls/interfaces");
var Icon = /** @class */ (function () {
    // #endregion Properties (1)
    // #region Constructors (1)
    function Icon(icon) {
        this.html = "<a><span uk-icon=\"" + icon + "\"></span></a>";
    }
    // #endregion Constructors (1)
    // #region Public Methods (1)
    Icon.prototype.toString = function () {
        return this.html;
    };
    return Icon;
}());
var groupSettings = [
    {
        groupId: interfaces_1.SettingsGroups.WORLD,
        icon: new Icon("world")
    },
    {
        groupId: interfaces_1.SettingsGroups.LIGHTS,
        icon: new Icon("bolt")
    },
    {
        groupId: interfaces_1.SettingsGroups.BACKGROUND,
        icon: new Icon("image")
    },
    {
        groupId: interfaces_1.SettingsGroups.CAMERA,
        icon: new Icon("video-camera")
    }
];
var GroupSettings = /** @class */ (function () {
    // #endregion Properties (2)
    // #region Constructors (1)
    function GroupSettings(parentEl, _domManager) {
        var _this = this;
        this._domManager = _domManager;
        // #region Properties (2)
        this._indexGroups = [];
        this.groups = {};
        var switchUlElem = this._domManager.createDomElement("ul", null, parentEl, [
            "uk-margin-remove",
            "uk-width-1-1",
            "sdv-control-panel-row-border",
            "shapediver-settings-tabs"
        ], { "uk-tab": "swiping: false;" });
        var contentUlElem = this._domManager.createDomElement("ul", null, parentEl, ["uk-switcher", "uk-width-1-1", "uk-margin-remove-top"]);
        groupSettings.forEach(function (gs) {
            var li = _this._domManager.createDomElement("li", null, switchUlElem, ["uk-padding-remove", "uk-width-1-4"]);
            li.innerHTML = gs.icon.toString();
            var contentLi = _this._domManager.createDomElement("li", null, contentUlElem, ["uk-width-1-1", "uk-margin-remove"], { "uk-grid": "" });
            _this.groups[gs.groupId] = {
                contentEl: contentLi,
                headerEl: li,
                id: gs.groupId
            };
            _this._indexGroups.push(_this.groups[gs.groupId]);
        });
    }
    // #endregion Constructors (1)
    // #region Public Methods (3)
    GroupSettings.prototype.getGroup = function (id) {
        return this.groups[id].contentEl;
    };
    GroupSettings.prototype.getGroupByIndex = function (index) {
        return this._indexGroups[index - 1].contentEl;
    };
    GroupSettings.prototype.headersClick = function (callback) {
        var _this = this;
        var _loop_1 = function (i) {
            this_1.groups[i].headerEl.addEventListener('click', function () { return callback(_this.groups[i].id); });
        };
        var this_1 = this;
        for (var i in this.groups) {
            _loop_1(i);
        }
    };
    return GroupSettings;
}());
exports.GroupSettings = GroupSettings;
