"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SDParametersCommitQueue = void 0;
var SDCommitQueue_1 = require("./SDCommitQueue");
var SDParametersCommitQueue = /** @class */ (function (_super) {
    __extends(SDParametersCommitQueue, _super);
    function SDParametersCommitQueue(api, container, use) {
        var _this = _super.call(this, api, container) || this;
        api.addEventListener(api.EVENTTYPE.SETTINGS_UPDATE, function (e) {
            if (e.settings.key === "commitParameters") {
                _this.useSettings(e.settings.valueNew);
            }
        });
        if (use) {
            _this.useSettings(true);
        }
        return _this;
    }
    /**
     * Use settings
     * @return void
     */
    SDParametersCommitQueue.prototype.useSettings = function (value) {
        this.createElements();
        this.use = value;
        this.clear();
    };
    return SDParametersCommitQueue;
}(SDCommitQueue_1.SDCommitQueue));
exports.SDParametersCommitQueue = SDParametersCommitQueue;
