"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SnapModule = void 0;
var SnapPoint_1 = require("./snapElements/SnapPoint");
var SnapLine_1 = require("./snapElements/SnapLine");
/** @ignore */
var THREE = require("../../externals/three");
var AnchorElement_1 = require("./anchorElements/AnchorElement");
var SnapUtils_1 = require("./SnapUtils");
/**
 * @packageDocumentation
 */
var SnapModule = /** @class */ (function () {
    /**
     * Creates the SnapModule.
     *
     * Cannot be created without the api reference.
     *
     * @param api
     */
    function SnapModule(api) {
        this._eventTypes = {
            /** "drag.start" */
            DRAG_START: 'drag.start',
            /** "drag.move" */
            DRAG_MOVE: 'drag.move',
            /** "drag.end" */
            DRAG_END: 'drag.end',
        };
        this._snapPoints = {};
        this._snapLines = {};
        this._anchors = {};
        this._eventListeners = {};
        if (!api)
            return null;
        this._eventListeners[this._eventTypes.DRAG_START] = {};
        this._eventListeners[this._eventTypes.DRAG_MOVE] = {};
        this._eventListeners[this._eventTypes.DRAG_END] = {};
        this._api = api;
        this._api.scene.addEventListener(this._eventTypes.DRAG_START, this.dragStart.bind(this));
        this._api.scene.addEventListener(this._eventTypes.DRAG_MOVE, this.dragMove.bind(this));
        this._api.scene.addEventListener(this._eventTypes.DRAG_END, this.dragEnd.bind(this));
        this._snapUtils = new SnapUtils_1.SnapUtils();
    }
    /**
     * Add a point which can be snapped to.
     *
     * @param pointDefinition
     * @returns the ID of the created element
     */
    SnapModule.prototype.addSnapPoint = function (pointDefinition) {
        var cleanedInput = this._snapUtils.cleanPointInput(pointDefinition);
        this._snapPoints[pointDefinition.id] = new SnapPoint_1.SnapPoint(cleanedInput);
        return cleanedInput.id;
    };
    /**
     * Remove a point from the list of points which can be snapped to.
     *
     * @param id
     */
    SnapModule.prototype.removeSnapPoint = function (id) {
        delete this._snapPoints[id];
    };
    /**
     * Add a line which can be snapped to.
     *
     * @param lineDefinition
     * @returns the ID of the created element
     */
    SnapModule.prototype.addSnapLine = function (lineDefinition) {
        var cleanedInput = this._snapUtils.cleanLineInput(lineDefinition);
        this._snapLines[lineDefinition.id] = new SnapLine_1.SnapLine(cleanedInput);
        return cleanedInput.id;
    };
    /**
     * Remove a line from the list of lines which can be snapped to.
     *
     * @param id
     */
    SnapModule.prototype.removeSnapLine = function (id) {
        delete this._snapLines[id];
    };
    /**
     * Add an anchor element that can snap to the snap elements.
     *
     * @param anchorDefinition
     * @returns the ID of the created element
     */
    SnapModule.prototype.addAnchorElement = function (anchorDefinition) {
        var cleanedInput = this._snapUtils.cleanAnchorInput(anchorDefinition);
        this._anchors[anchorDefinition.id] = new AnchorElement_1.AnchorElement(cleanedInput);
        return cleanedInput.id;
    };
    /**
     * Remove an anchor element from the list of anchor elements.
     *
     * @param id
     */
    SnapModule.prototype.removeAnchorElement = function (id) {
        delete this._anchors[id];
    };
    /**
     * Add an event listener for the specified type with the specified callback.
     *
     * @param type {@link EVENTTYPE}
     * @param cb
     */
    SnapModule.prototype.addEventListener = function (type, cb) {
        if (!this._eventListeners[type])
            return '';
        var id = this._snapUtils.createRandomId();
        this._eventListeners[type][id] = cb;
        return id;
    };
    /**
     * Remove an event listener.
     *
     * @param id
     */
    SnapModule.prototype.removeEventListener = function (id) {
        delete this._eventListeners[this._eventTypes.DRAG_START][id];
        delete this._eventListeners[this._eventTypes.DRAG_MOVE][id];
        delete this._eventListeners[this._eventTypes.DRAG_END][id];
    };
    Object.defineProperty(SnapModule.prototype, "EVENTTYPE", {
        /**
         * Get all possible event types.
         */
        get: function () {
            return this._eventTypes;
        },
        enumerable: false,
        configurable: true
    });
    SnapModule.prototype.dragStart = function (e) {
        this._lastEvent = {
            dragEvent: e,
            snap: false,
            snappedBefore: e.obj.snap === true || e.obj.snap === false ? e.obj.snap : false
        };
        this._prevRotationMatrix = e.obj.rotationMatrix ? e.obj.rotationMatrix.clone() : new THREE.Matrix4();
        this._startPositionWorld = e.worldPos.clone();
        this._startPositionModel = e.worldPos.clone().sub(e.dragPosStart);
        // forward event with new data
        for (var id in this._eventListeners[this._eventTypes.DRAG_START])
            this._eventListeners[this._eventTypes.DRAG_START][id](e);
    };
    SnapModule.prototype.dragMove = function (e) {
        var _this = this;
        this._lastEvent = {
            dragEvent: e,
            snap: false,
            snappedBefore: e.obj.snap
        };
        // get distance between starting position and current position
        var movementDifference = e.worldPos.clone().sub(this._startPositionWorld);
        var currentPositionModel = this._startPositionModel.clone().add(movementDifference);
        // store all snaps to point
        var snapsPoints = this.evaluateSnapElements(this._snapPoints, movementDifference, currentPositionModel);
        this._snapUtils.sortDistance(snapsPoints);
        if (snapsPoints.length > 0 && snapsPoints[0].snapInfo.snap)
            return this.snapToElement(e, movementDifference, snapsPoints[0]);
        // store all snaps to lines
        var snapsLines = this.evaluateSnapElements(this._snapLines, movementDifference, currentPositionModel);
        this._snapUtils.sortDistance(snapsLines);
        if (snapsLines.length > 0 && snapsLines[0].snapInfo.snap)
            return this.snapToElement(e, movementDifference, snapsLines[0]);
        e.obj.snap = false;
        var newPosition = currentPositionModel.clone();
        e.obj.position.copy(newPosition);
        e.obj.setRotationFromMatrix(new THREE.Matrix4());
        e.obj.nonRotatedPosition = null;
        e.obj.rotationMatrix = null;
        this._lastEvent.transformPoint = function (p) {
            var point = _this.transformPoint(p.clone(), _this._startPositionWorld.clone(), new THREE.Matrix4().getInverse(_this._prevRotationMatrix));
            return point.clone().add(movementDifference);
        };
        this._lastEvent.position = e.worldPos.clone();
        for (var i = 0; i < e.obj.additionalDragObjects.length; i++) {
            e.obj.additionalDragObjects[i].snap = false;
            e.obj.additionalDragObjects[i].rotationMatrix = null;
            e.obj.additionalDragObjects[i].position.copy(newPosition.clone().sub(e.obj.additionalDragObjects[i].diffToMain));
            e.obj.additionalDragObjects[i].nonRotatedPosition = null;
            e.obj.additionalDragObjects[i].setRotationFromMatrix(new THREE.Matrix4());
        }
        // forward event with new data
        for (var id in this._eventListeners[this._eventTypes.DRAG_MOVE])
            this._eventListeners[this._eventTypes.DRAG_MOVE][id](this._lastEvent);
    };
    SnapModule.prototype.transformPoint = function (position, center, rotationMatrix) {
        // translate so that the object would be at zero
        var translatedAnchor = position.clone().sub(center);
        // rotate there
        var translatedRotatedAnchor = translatedAnchor.clone().applyMatrix4(rotationMatrix);
        // translate it back
        var rotatedAnchor = translatedRotatedAnchor.clone().add(center);
        // currently rotated anchor
        return rotatedAnchor.clone();
    };
    SnapModule.prototype.evaluateSnapElements = function (snapElements, movementDifference, currentPositionModel) {
        var e_1, _a;
        var snaps = [];
        // go through all snap points
        for (var snapElementId in snapElements) {
            var snapElement = snapElements[snapElementId];
            // gather anchorIds for this snap point
            var ids = snapElement.anchorIds;
            // empty array = all anchors
            if (ids.length === 0)
                ids = Object.keys(this._anchors);
            try {
                // go through all anchors
                for (var ids_1 = (e_1 = void 0, __values(ids)), ids_1_1 = ids_1.next(); !ids_1_1.done; ids_1_1 = ids_1.next()) {
                    var anchorId = ids_1_1.value;
                    var anchor = this._anchors[anchorId];
                    if (!anchor)
                        break;
                    // calculate the rotation matrix that has to be applied to the object
                    var rotationMatrix = anchor.rotationMatrix.clone().multiply(snapElement.rotationMatrix.clone());
                    /**
                     * OPTION 1: direct distance between anchor and snap point
                     */
                    // const currentAnchorPosition = anchor.position.clone().add(movementDifference);
                    // const snapInfo: ISnapInfo = snapElement.snap(currentAnchorPosition);
                    /**
                     * OPTION 2: rotate around object center (or rotation center if defined)
                     */
                    var rotatedAnchor = this.transformPoint(anchor.position.clone(), this._startPositionWorld.clone(), rotationMatrix);
                    var currentRotatedAnchorPosition = rotatedAnchor.clone().add(movementDifference);
                    var snapInfo = snapElement.snap(currentRotatedAnchorPosition);
                    var movementSnapping = snapInfo.position.clone().sub(currentRotatedAnchorPosition);
                    var nonRotatedPosition = currentPositionModel.clone().add(movementSnapping);
                    snaps.push({ anchor: anchor, snapElement: snapElement, snapInfo: snapInfo, nonRotatedPosition: nonRotatedPosition, rotationMatrix: rotationMatrix });
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (ids_1_1 && !ids_1_1.done && (_a = ids_1.return)) _a.call(ids_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
        return snaps;
    };
    SnapModule.prototype.snapToElement = function (e, movementDifference, snap) {
        var _this = this;
        var anchor = snap.anchor;
        var snapInfo = snap.snapInfo;
        var snapElement = snap.snapElement;
        var nonRotatedPosition = snap.nonRotatedPosition.clone();
        var rotationMatrix = snap.rotationMatrix;
        var snappedBefore = e.obj.snap;
        e.obj.snappedBefore = snappedBefore;
        // currently rotated anchor
        var modelPosition = e.dragPosStart.clone();
        var modelPositionRotated = e.dragPosStart.clone().applyMatrix4(rotationMatrix);
        var worldPosition = nonRotatedPosition.clone();
        // we have to do this for and back rotation as three.js rotates everything automatically,
        // even though we actually only want to rotate the geometry itself.
        var newPosition = new THREE.Vector3().sub(modelPositionRotated).add(modelPosition).add(worldPosition);
        var anchortemp = {
            dragEvent: e,
            snap: true,
            snappedBefore: snappedBefore,
            distance: snapInfo.distance,
            anchorId: anchor.id,
            position: newPosition.clone(),
            nonRotatedPosition: nonRotatedPosition.clone().add(modelPosition),
            rotationMatrix: snapElement.rotationMatrix.clone(),
            transformPoint: function (p) {
                var point = _this.transformPoint(p.clone(), _this._startPositionWorld.clone(), new THREE.Matrix4().getInverse(_this._prevRotationMatrix));
                return _this.transformPoint(point.clone(), _this._startPositionWorld.clone(), rotationMatrix).add(nonRotatedPosition.clone().add(modelPosition).sub(_this._startPositionWorld));
            }
        };
        e.obj.snap = true;
        e.obj.position.copy(newPosition);
        e.obj.nonRotatedPosition = nonRotatedPosition.clone().add(modelPosition);
        e.obj.setRotationFromMatrix(rotationMatrix);
        e.obj.rotationMatrix = rotationMatrix.clone();
        for (var i = 0; i < e.obj.additionalDragObjects.length; i++) {
            e.obj.additionalDragObjects[i].snap = true;
            e.obj.additionalDragObjects[i].position.copy(newPosition.clone().sub(e.obj.additionalDragObjects[i].diffToMain));
            e.obj.additionalDragObjects[i].nonRotatedPosition = nonRotatedPosition.clone().add(modelPosition);
            e.obj.additionalDragObjects[i].rotationMatrix = rotationMatrix.clone();
            e.obj.additionalDragObjects[i].setRotationFromMatrix(rotationMatrix);
        }
        // forward event with new data
        for (var id in this._eventListeners[this._eventTypes.DRAG_MOVE])
            this._eventListeners[this._eventTypes.DRAG_MOVE][id](anchortemp);
        this._lastEvent = {
            dragEvent: null,
            snap: anchortemp.snap,
            snappedBefore: snappedBefore,
            distance: anchortemp.distance,
            anchorId: anchortemp.anchorId,
            position: anchortemp.position,
            nonRotatedPosition: anchortemp.nonRotatedPosition,
            rotationMatrix: anchortemp.rotationMatrix,
            transformPoint: anchortemp.transformPoint
        };
        return;
    };
    SnapModule.prototype.dragEnd = function (e) {
        this._lastEvent.dragEvent = e;
        var snappedBefore = e.obj.snap;
        e.obj.snappedBefore = snappedBefore;
        e.obj.snap = false;
        // forward event with new data
        for (var id in this._eventListeners[this._eventTypes.DRAG_END])
            this._eventListeners[this._eventTypes.DRAG_END][id](this._lastEvent);
    };
    return SnapModule;
}());
exports.SnapModule = SnapModule;
