"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PbrMaterialConverter = void 0;
var PbrMaterialConverter = /** @class */ (function () {
    function PbrMaterialConverter() {
        // #region Properties (2)
        this._dielectricSpecular = [0.04, 0.04, 0.04];
        this._epsilon = 1e-6;
        // #endregion Private Methods (6)
    }
    // #endregion Properties (2)
    // #region Public Methods (1)
    PbrMaterialConverter.prototype.convertToMetallicRoughness = function (specGlossiness) {
        var _this = this;
        var diffuse = specGlossiness.diffuseFactor;
        var specular = specGlossiness.specularFactor;
        var glossiness = specGlossiness.glosinessFactor;
        var oneMinusSpecularStrength = 1 - this.getMaxComponent(specular);
        var metallic = this.solveMetallic(this.getPerceivedBrightness(diffuse), this.getPerceivedBrightness(specular), oneMinusSpecularStrength);
        var baseColorFromDiffuse = diffuse.map(function (x) { return x * (oneMinusSpecularStrength / (1 - _this._dielectricSpecular[0]) / Math.max(1 - metallic, _this._epsilon)); });
        var baseColorFromSpecular = this.subtract(specular, this._dielectricSpecular.map(function (x) { return x * (1 - metallic); })
            .map(function (x) { return x * (1 / Math.max(metallic, _this._epsilon)); }));
        var baseColor = this.lerp(baseColorFromDiffuse, baseColorFromSpecular, metallic * metallic).map(function (x) { return _this.clamp(x); });
        return {
            baseColor: baseColor,
            metallic: metallic,
            roughness: 1 - glossiness
        };
    };
    // #endregion Public Methods (1)
    // #region Private Methods (6)
    PbrMaterialConverter.prototype.clamp = function (v, min, max) {
        if (min === void 0) { min = 0; }
        if (max === void 0) { max = 1; }
        if (v > max)
            return max;
        else if (v < min)
            return min;
        return v;
    };
    PbrMaterialConverter.prototype.getMaxComponent = function (from) {
        return Math.max.apply(Math, __spread(from));
    };
    PbrMaterialConverter.prototype.getPerceivedBrightness = function (color) {
        var r = color[0];
        var g = color[1];
        var b = color[2];
        return Math.sqrt(0.299 * r * r + 0.587 * g * g + 0.114 * b * b);
    };
    PbrMaterialConverter.prototype.lerp = function (value1, value2, amount) {
        amount = amount < 0 ? 0 : amount;
        amount = amount > 1 ? 1 : amount;
        if (Array.isArray(value1)) {
            var res = [];
            for (var i = 0; i < value1.length; i++) {
                res.push(this.lerp(value1[i], value2[i], amount));
            }
            return res;
        }
        return (value1 + (value2 - value1) * amount);
    };
    PbrMaterialConverter.prototype.solveMetallic = function (diffuse, specular, oneMinusSpecularStrength) {
        if (specular < this._dielectricSpecular[0]) {
            return 0;
        }
        var a = this._dielectricSpecular[0];
        var b = diffuse * oneMinusSpecularStrength / (1 - this._dielectricSpecular[0]) + specular - 2 * this._dielectricSpecular[0];
        var c = this._dielectricSpecular[0] - specular;
        var d = Math.max(b * b - 4 * a * c, 0);
        return this.clamp((-b + Math.sqrt(d)) / (2 * a), 0, 1);
    };
    PbrMaterialConverter.prototype.subtract = function (a, b) {
        var res = [];
        for (var i = 0; i < a.length; i++) {
            res.push(a[i] - b[i]);
        }
        return res;
    };
    return PbrMaterialConverter;
}());
exports.PbrMaterialConverter = PbrMaterialConverter;
