"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CameraEventManager = void 0;
var CameraEventManager = /** @class */ (function () {
    // #endregion Properties (1)
    // #region Constructors (1)
    function CameraEventManager(_controls) {
        this._controls = _controls;
        this._currentMousePosition = { x: 0, y: 0 };
        this.addEventListeners();
    }
    Object.defineProperty(CameraEventManager.prototype, "cameraControlsManager", {
        // #endregion Constructors (1)
        // #region Public Accessors (1)
        set: function (cameraControlsManager) {
            this._cameraControlsManager = cameraControlsManager;
        },
        enumerable: false,
        configurable: true
    });
    // #endregion Public Accessors (1)
    // #region Public Methods (1)
    CameraEventManager.prototype.dispose = function () {
        this.removeEventListerners();
    };
    // #endregion Public Methods (1)
    // #region Private Methods (11)
    CameraEventManager.prototype.addEventListeners = function () {
        this._controls.domElement.addEventListener("mousewheel", this.onMouseWheel.bind(this), { passive: false });
        this._controls.domElement.addEventListener("MozMousePixelScroll", this.onMouseWheel.bind(this), { passive: false }); // firefox
        this._controls.domElement.addEventListener("mousedown", this.onMouseDown.bind(this), { passive: false });
        this._controls.domElement.addEventListener("mousemove", this.onMouseMove.bind(this), { passive: false });
        this._controls.domElement.addEventListener("mouseup", this.onMouseUp.bind(this), { passive: false });
        this._controls.domElement.addEventListener("mouseout", this.onMouseUp.bind(this), { passive: false });
        this._controls.domElement.addEventListener("touchstart", this.onTouchStart.bind(this), { passive: false });
        this._controls.domElement.addEventListener("touchmove", this.onTouchMove.bind(this), { passive: false });
        this._controls.domElement.addEventListener("touchend", this.onTouchEnd.bind(this), { passive: false });
        window.addEventListener("keydown", this.onKeyDown.bind(this), { passive: false });
        window.addEventListener("mousemove", this.onKeyDownMousePositionHelper.bind(this), { passive: false });
        // just prevent right click menu
        this._controls.domElement.addEventListener("contextmenu", this.onContextMenu.bind(this), { passive: false });
    };
    CameraEventManager.prototype.onContextMenu = function (event) {
        if (this._controls.enabled === false)
            return;
        event.preventDefault();
    };
    CameraEventManager.prototype.onKeyDown = function (event) {
        if (this._controls.enabled === false)
            return;
        if (this._controls.domElement === document.elementFromPoint(this._currentMousePosition.x, this._currentMousePosition.y))
            this._cameraControlsManager.onKeyDown(event);
    };
    CameraEventManager.prototype.onKeyDownMousePositionHelper = function (event) {
        this._currentMousePosition = { x: event.pageX, y: event.pageY };
    };
    CameraEventManager.prototype.onMouseDown = function (event) {
        if (this._controls.enabled === false)
            return;
        event.preventDefault();
        this._cameraControlsManager.onMouseDown(event);
    };
    CameraEventManager.prototype.onMouseMove = function (event) {
        if (this._controls.enabled === false)
            return;
        event.preventDefault();
        this._cameraControlsManager.onMouseMove(event);
    };
    CameraEventManager.prototype.onMouseUp = function (event) {
        if (this._controls.enabled === false)
            return;
        this._cameraControlsManager.onMouseUp(event);
    };
    CameraEventManager.prototype.onMouseWheel = function (event) {
        if (this._controls.enabled === false)
            return;
        event.preventDefault();
        event.stopPropagation();
        this._cameraControlsManager.onMouseWheel(event);
    };
    CameraEventManager.prototype.onTouchEnd = function (event) {
        if (this._controls.enabled === false)
            return;
        this._cameraControlsManager.onTouchEnd(event);
    };
    CameraEventManager.prototype.onTouchMove = function (event) {
        if (this._controls.enabled === false)
            return;
        this._cameraControlsManager.onTouchMove(event);
    };
    CameraEventManager.prototype.onTouchStart = function (event) {
        if (this._controls.enabled === false)
            return;
        this._cameraControlsManager.onTouchStart(event);
    };
    CameraEventManager.prototype.removeEventListerners = function () {
        this._controls.domElement.removeEventListener("mousewheel", this.onMouseWheel.bind(this), false);
        this._controls.domElement.removeEventListener("MozMousePixelScroll", this.onMouseWheel.bind(this), false); // firefox
        this._controls.domElement.removeEventListener("mousedown", this.onMouseDown.bind(this), false);
        this._controls.domElement.removeEventListener("mousemove", this.onMouseMove.bind(this), false);
        this._controls.domElement.removeEventListener("mouseup", this.onMouseUp.bind(this), false);
        this._controls.domElement.removeEventListener("mouseout", this.onMouseUp.bind(this), false);
        this._controls.domElement.removeEventListener("touchstart", this.onTouchStart.bind(this), false);
        this._controls.domElement.removeEventListener("touchmove", this.onTouchMove.bind(this), false);
        this._controls.domElement.removeEventListener("touchend", this.onTouchEnd.bind(this), false);
        window.removeEventListener("keydown", this.onKeyDown.bind(this), false);
        window.removeEventListener("mousemove", this.onKeyDownMousePositionHelper.bind(this), false);
        this._controls.domElement.removeEventListener("contextmenu", this.onContextMenu.bind(this), false);
    };
    return CameraEventManager;
}());
exports.CameraEventManager = CameraEventManager;
