"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BufferView = void 0;
var Accessor_1 = require("./Accessor");
var BufferView = /** @class */ (function () {
    // #endregion Properties (10)
    // #region Constructors (1)
    function BufferView(id, gltf, buffer) {
        var _this = this;
        this.id = id;
        this.buffer = buffer;
        // #region Properties (10)
        this.accessors = [];
        this.interleavedBuffer = null;
        this.isInterleaved = false;
        this.numOfTypeArrayElementsPerVertex = 0;
        this.bufferViewRef = gltf.bufferViews[id];
        this.byteOffset = this.bufferViewRef.byteOffset;
        this.byteLength = this.bufferViewRef.byteLength;
        this.byteStride = this.bufferViewRef.byteStride || 0;
        var thisAcc = gltf.accessors ? gltf.accessors.filter(function (x) { return x.bufferView == _this.id; }) : [];
        if (thisAcc.length > 0) {
            var a = thisAcc[0];
            this.isInterleaved = a.count * this.byteStride === this.byteLength;
        }
        this.numOfTypeArrayElementsPerVertex = 0;
        this.dataView = new DataView(this.buffer.buffer, this.byteOffset, this.byteLength);
        for (var i in gltf.accessors) {
            if (gltf.accessors[i].bufferView == id) {
                var accessor = new Accessor_1.Accessor(i, gltf, this.buffer, this);
                this.numOfTypeArrayElementsPerVertex += accessor.elementComponentsSize;
                this.accessors.push(accessor);
            }
        }
    }
    return BufferView;
}());
exports.BufferView = BufferView;
