/**
 * __ShapeDiver 3D Viewer Application__, copyright (c) 2018 _ShapeDiver GmbH_
 *
 * *ViewerAppExportManager.js*
 *
 * ### Content
 *   * Export functionality of ViewerApp
 *
 * @module ViewerAppExportManager
 * @author Alex Schiftner <alex@shapediver.com>
 */

/**
  * Imported global utilities
  */
var GlobalUtils = require('../../shared/util/GlobalUtils');

/**
  * Imported plugin constant definitions
  */
var pluginConstants = require('../../shared/constants/PluginConstantsGlobal');

/**
  * Imported message constant definitions
  */
var messagingConstants = require('../../shared/constants/MessagingConstants');

/**
  * Imported message prototype
  */
var MessagePrototype = require('../../shared/messages/MessagePrototype');

/**
 * Constructor of the ViewerAppExportManager mixin
 * @mixin ViewerAppExportManager
 * @author Alex Schiftner <alex@shapediver.com>
 *
 * @param {Object} references - References to other managers
 * @param {Object} references.pluginManager - Reference to the plugin manager
 * @param {Object} references.parameterManager - Reference to the parameter manager
 */
var ViewerAppExportManager = function(___refs) {

  var that = this;

  var _pluginManager = ___refs.pluginManager;
  var _parameterManager = ___refs.parameterManager;

  /**
   * Object for collecting public members, this object will be returned instead of the default "this"
   */
  var _o = {};

  /**
   * Private container for exports
   */
  var _exports = [];

  /**
   * Message part object for EXPORT_REGISTERED_BATCH
   */
  var _messagePartBatch = {};

  /**
   * Register multiple exports
   *
   * @public
   * @param {module:JSONExport~JSONExportSet} expdef - Definition of the exports
   * @param {String} plugin - Runtime id of plugin the exports belong to
   * @return {String[]} ids of the exports on success, undefined on error
   */
  _o.registerMultipleExports = function(expdef, plugin) {
    // clear the last message part batch
    _messagePartBatch = {};
    var ids = [];
    Object.keys(expdef).forEach( function(id) {
      // add id as property of export definition
      let exportDef = GlobalUtils.deepCopy( expdef[id] );
      exportDef.id = id;
      let res = _o.registerExport(exportDef, plugin);
      if ( res === undefined )
        return;
    });
    
    that.message(messagingConstants.messageTopics.EXPORT_REGISTERED_BATCH, new MessagePrototype(
      messagingConstants.messageDataTypes.EXPORT_DEFINITION,
      _messagePartBatch
    ));
    return ids;
  };

  /**
   * Register an export
   *
   * @public
   * @param {module:JSONExport~JSONExportSet} def - Definition of the export
   * @param {String} plugin - Runtime id of plugin the export belongs to
   * @return {String} id of the export on success, undefined on error
   */
  _o.registerExport = function(def, plugin) {
    var scope = 'ViewerAppExportManager.registerExport';

    // export sanity check
    if ( plugin === undefined || !GlobalUtils.typeCheck(plugin, 'string') ) {
      that.debug(scope, 'No plugin specified');
      return;
    }
    if ( def === undefined || typeof def !== 'object' ) {
      that.debug(scope, 'Definition of export missing');
      return;
    }

    // set plugin as part of export definition
    def.plugin = plugin;

    // check if def has a unique id, if not create one
    if ( def.id === undefined || !GlobalUtils.typeCheck(def.id, 'string') ) {
      def.id = GlobalUtils.createRandomId();
      that.debug(scope, 'No id was specified, using ' + def.id);
    }

    // check if a export with the given id and plugin is already registered
    if ( undefined !== _o.getUniqueExportByProperties({id: def.id, plugin: plugin}) ) {
      that.debug(scope, 'Export with id ' + def.id + ' and plugin ' + plugin + ' already exists');
      return;
    }

    // ensure property hidden exists
    if (!def.hasOwnProperty('hidden')) def.hidden = false;

    // export should have a name
    if ( undefined === def.name ) {
      that.warn(scope, 'Export without name:', def);
    }

    // register export
    _exports.push(def);

    // add this as part of the batch event EXPORT_REGISTERED_BATCH
    _messagePartBatch[def.id] = GlobalUtils.deepCopy(def);

    // notify world about registration of new export
    let messagePart = {};
    messagePart[def.id] = GlobalUtils.deepCopy(def);
    that.message( messagingConstants.messageTopics.EXPORT_REGISTERED, new MessagePrototype(
      messagingConstants.messageDataTypes.EXPORT_DEFINITION,
      messagePart
    )
    );

    // success - return id
    return def.id;
  };

  /**
   * Register configuration settings related to exports which are sent by a plugin
   *
   * Handles the following properties of the config object:
   *  * controlNames,
   *  * controlOrder,
   *  * parametersHidden
   * Updates the settings 'name', 'order', and 'hidden' of parameters accordingly.
   * @public
   * @param {module:JSONConfig~JSONConfig} config - configuration object
   * @return {Boolean} true in case of success, false otherwise
   */
  _o.registerConfig = function(config) {
    // collect updated export definitions, for sending messages after doing all updates
    let updatedExports = {};

    // controlNames property
    if ( config.controlNames && typeof config.controlNames === 'object' ) {
      let cn = config.controlNames;
      Object.keys(cn).forEach( (id) => {
        let p = _o.getUniqueExportByProperties({id: id, plugin: config.plugin});
        if ( p ) {
          // remember original name, if it has not been remember before
          if (p._name === undefined) {
            p._name = GlobalUtils.deepCopy(p.name);
          }
          p.name = GlobalUtils.deepCopy(cn[id]);
          updatedExports[p.id] = p;
        }
      });
    }

    // controlOrder property
    if ( config.controlOrder && Array.isArray(config.controlOrder) ) {
      let co = config.controlOrder;
      for (let i=0; i<co.length; i++) {
        let id = co[i];
        let p = _o.getUniqueExportByProperties({id: id, plugin: config.plugin});
        if ( p ) {
          p.order = i;
          updatedExports[p.id] = p;
        }
      }
    }

    // parametersHidden property
    if ( config.parametersHidden && Array.isArray(config.parametersHidden) ) {
      let ph = config.parametersHidden;
      for (let i=0; i<ph.length; i++) {
        let id = ph[i];
        let p = _o.getUniqueExportByProperties({id: id, plugin: config.plugin});
        if ( p ) {
          p.hidden = true;
          updatedExports[p.id] = p;
        }
      }
    }

    // send message reporting the update to the export definition
    Object.keys(updatedExports).forEach(function(id) {
      let def = GlobalUtils.deepCopy(updatedExports[id]);
      let messagePart = {};
      messagePart[def.id] = def;
      that.message( messagingConstants.messageTopics.EXPORT_UPDATE, new MessagePrototype(
        messagingConstants.messageDataTypes.EXPORT_DEFINITION,
        messagePart
      )
      );
    });

    return true;
  };

  /**
   * Get a partial {@link module:JSONConfig~JSONConfig configuration object},
   * containing the data of the configuration object related to exports:
   *  * controlNames,
   *  * controlOrder,
   *  * parametersHidden
   *
   * @public
   * @param {String} plugin - runtime id of the plugin for which the configuration object shall be returned
   * @return {module:JSONConfig~JSONConfig} partial configuration object in case of success, undefined in case of error
   */
  _o.getConfig = function(plugin) {
    // get parameters for plugin
    let exportDefinitions = _o.getExportDefinitions();
    // check if we got sth
    if (!Array.isArray(exportDefinitions)) return;
    // filter by plugin
    exportDefinitions = exportDefinitions.filter((e) => (e.plugin === undefined || e.plugin === plugin));
    if (exportDefinitions.length === 0) return {};
    // create partial config object
    let config = {};
    exportDefinitions.forEach((def) => {
      // parameter name
      if (GlobalUtils.typeCheck(def.name, 'string') && GlobalUtils.typeCheck(def._name, 'string')) {
        config.controlNames = config.controlNames || [];
        config.controlNames.push({id: def.id, name: def.name});
      }
      // hidden?
      if (GlobalUtils.typeCheck(def.hidden, 'boolean') && def.hidden) {
        config.parametersHidden = config.parametersHidden || [];
        config.parametersHidden.push(def.id);
      }
      // order
      if (GlobalUtils.typeCheck(def.order, 'number')) {
        config.controlOrder = config.controlOrder || [];
        config.controlOrder.push({id: def.id, order: def.order});
      }
    });
    return config;
  };

  /**
   * Update properties of a registered export
   * The following properties can be updated: name(string), order(number), hidden(boolean)
   *
   * @public
   * @param {module:JSONExport~JSONExportDefinition} def - new definition of the export
   * @return {Boolean} true on success, false on error
   */
  _o.updateExport = function(def) {
    let scope = 'ViewerAppExportManager.updateExport';

    // sanity check
    if ( !def || typeof def !== 'object' ) {
      that.debug(scope, 'Definition of export missing');
      return false;
    }

    // get unique export matching def
    let idx = getUniqueExportIdxByRequestObject( def );
    if ( idx === undefined ) {
      that.debug(scope, 'Export not found', def);
      return false;
    }
    let p = _exports[idx];

    // update export settings name, order, hidden
    if ( typeof def.name === 'string' && def.name.length > 0 && def.name !== p.name ) {
      if (p._name === undefined) {
        p._name = GlobalUtils.deepCopy(p.name);
      }
      p.name = GlobalUtils.deepCopy(def.name);
    }
    if ( typeof def.order === 'number' ) {
      p.order = def.order;
    }
    if ( typeof def.hidden === 'boolean' ) {
      p.hidden = def.hidden;
    }

    // send message reporting the update to the export definition
    let defNew = GlobalUtils.deepCopy(p);
    let messagePart = {};
    messagePart[defNew.id] = defNew;
    that.message( messagingConstants.messageTopics.EXPORT_UPDATE, new MessagePrototype(
      messagingConstants.messageDataTypes.EXPORT_DEFINITION,
      messagePart
    )
    );

    // success
    return true;
  };

  /**
  * Update properties of registered exports
  * The following properties can be updated: name(string), order(number), hidden(boolean)
   *
   * @public
   * @param {module:JSONExport~JSONExportDefinition[]} arrDef - new definitions of the exports
   * @return {Boolean} true on success, false on error
   */
  _o.updateMultipleExports = function(arrDef) {
    if (!Array.isArray(arrDef)) arrDef = [arrDef];
    return arrDef.every((def) => (_o.updateExport(def)));
  };

  /**
   * Deregister an export
   * Id and plugin of the export must be specified, because an export id might occur
   * several times for different instances of plugins.
   * @public
   * @param {String} id - unique id of the export to deregister
   * @param {String} plugin - runtime id of the plugin
   * @return {Boolean} true in case of success, false otherwise
   */
  _o.deregisterExport = function(id, plugin) {
    var scope = 'ViewerAppExportManager.deregisterExport';
    var settings = {};
    settings.id = id;
    if ( plugin !== undefined )
      settings.plugin = plugin;
    var idx = getUniqueExportIdxByProperties(settings);
    if ( idx === undefined ) {
      that.error(scope, 'Export with id ' + id + ' and plugin ' + plugin + ' not found');
      return false;
    }
    // #SS-39 to be implemented: remove export from user interface
    var def = _exports.splice(idx, 1)[0];
    that.info(scope, 'Deregistered export "' + def.name + '" with id ' + id );
    return true;
  };

  /**
   * Get export by its id and optional plugin runtime id.
   * Fails if there are multiple exports matching the search criteria.
   * @public
   * @param {String} id - unique id of the export to get
   * @param {String} [plugin] - runtime id of plugin the export belongs to
   * @return {Object} export object on success, undefined on error
   */
  _o.getExportById = function(id, plugin) {
    var settings = {};
    settings.id = id;
    if ( plugin !== undefined ) {
      settings.plugin = plugin;
    }
    return _o.getUniqueExportByProperties(settings);
  };

  /**
   * Get unique export by its name and optional plugin runtime id.
   * Fails if there are multiple export matching the search criteria.
   * @public
   * @param {String} name - name of the export to get
   * @param {String} [plugin] - runtime id of plugin the export belongs to
   * @return {Object} export object on success, undefined on error
   */
  _o.getExportByName = function(name, plugin) {
    var settings = {};
    settings.name = name;
    if ( plugin !== undefined ) {
      settings.plugin = plugin;
    }
    return _o.getUniqueExportByProperties(settings);
  };

  /**
   * Get export by its id or its name and optional plugin runtime id.
   * @public
   * @param {String} idOrName - unique id of the export to get, or its name
   * @param {String} [plugin] - runtime id of plugin the export belongs to
   * @return {Object} export object on success, undefined on error
   */
  _o.getExportByIdOrName = function(idOrName, plugin) {
    // try to find by id
    var settings = {};
    settings.id = idOrName;
    if ( plugin !== undefined ) {
      settings.plugin = plugin;
    }
    var param = _o.getUniqueExportByProperties(settings);
    if ( param !== undefined ) {
      return param;
    }
    // try to find by name
    delete settings.id;
    settings.name = idOrName;
    return _o.getUniqueExportByProperties(settings);
  };

  /**
   * Get exports by one or more of their properties. Property values must match precisely.
   * @public
   * @param {Object} settings - properties to search for
   * @param {String} [settings.id] - id of export
   * @param {String} [settings.name] - name of export
   * @param {String} [settings.plugin] - runtime id of plugin the export belongs to
   * @param {String} [settings.type] - type of export
   * @return {Object[]} array of matching export objects, may be empty
   */
  _o.getExportsByProperties = function(settings) {
    // filter returns an empty array if no match
    return _exports.filter( function(p) {
      return Object.keys(settings).every( function(key) {
        if ( p.hasOwnProperty(key) && p[key] === settings[key] ) {
          return true;
        }
        return false;
      });
    });
  };

  /**
   * Get export indices by one or more of their properties. Property values must match precisely.
   * @private
   * @param {Object} settings - properties to search for
   * @param {String} [settings.id] - id of export
   * @param {String} [settings.name] - name of export
   * @param {String} [settings._name] - original name of export
   * @param {String} [settings.plugin] - runtime id of plugin the export belongs to
   * @param {String} [settings.type] - type of export
   * @return {Object[]} array of matching export objects, may be empty
   */
  var getExportsIdxByProperties = function(settings) {
    var indices = [];
    _exports.forEach( function(p, idx) {
      var match = Object.keys(settings).every( function(key) {
        if ( p.hasOwnProperty(key) && p[key] === settings[key] ) {
          return true;
        }
        return false;
      });
      if ( match )
        indices.push(idx);
    });
    return indices;
  };

  /**
   * Get index of uniquely matching exports for the given settings. Does not return an index if multiple exports match the settings.
   * @private
   * @param {Object} settings - properties to search for
   * @param {String} [settings.id] - id of export
   * @param {String} [settings.name] - name of export
   * @param {String} [settings._name] - original name of export
   * @param {String} [settings.plugin] - runtime id of plugin the export belongs to
   * @param {String} [settings.type] - type of export
   * @return {Number} index of unique matching export, undefined if not found
   */
  var getUniqueExportIdxByProperties = function(settings) {
    var indices = getExportsIdxByProperties(settings);
    if ( indices.length === 1 ) {
      return indices[0];
    }
  };

  /**
   * Get uniquely matching export for the given properties. Does not return an export if multiple ones match the settings.
   * @public
   * @param {Object} settings - properties to search for
   * @param {String} [settings.id] - id of export
   * @param {String} [settings.name] - name of export
   * @param {String} [settings._name] - original name of export
   * @param {String} [settings.plugin] - runtime id of plugin the export belongs to
   * @param {String} [settings.type] - type of export
   * @return {Object} unique matching export, undefined if not found
   */
  _o.getUniqueExportByProperties = function(settings) {
    var params = _o.getExportsByProperties(settings);
    if ( params.length === 1 ) {
      return params[0];
    }
  };

  /**
   * Given an export id (which might occur in several plugins), get a unique identifier for it by
   * appending the plugin runtime id, in case the export id occurs several times
   * @public
   * @param {Object} p - Export object
   * @return {String} Unique export identifier, undefined on error
   */
  // var getUniqueExportIdentifier = function(p) {
  //   let key = p.id;
  //   if ( _o.getExportsByProperties({id: key}).length > 1 ) {
  //     key = key + '__' + p.plugin;
  //   }
  //   return key;
  // };

  /**
   * Get definitions of all registered exports
   * @public
   * @return {module:JSONExport~JSONExportDefinition[]} array of export definitions, undefined on error
   */
  _o.getExportDefinitions = function() {
    // get definitions of all registered exports, return them
    var exports = [];
    _exports.forEach( function(p) {
      var def = GlobalUtils.deepCopy( {id: p.id, uid: p.uid, name: p.name, type: p.type, plugin: p.plugin, group: p.group, hidden: p.hidden, order: p.order, _name: p._name} );
      // in case names are not unique, use a combination of id and plugin as key
      exports.push(def);
    });
    // sort exports according to 'plugin' and 'order', or 'plugin' and 'name'
    exports.sort((a,b) => {
      let as, bs;
      as = a.plugin;
      bs = b.plugin;
      if (typeof a.order === 'number' && typeof b.order === 'number') {
        as += Math.floor(1000*a.order).toString().padStart(8,'0');
        bs += Math.floor(1000*b.order).toString().padStart(8,'0');
      } else {
        as += a.name;
        bs += b.name;
      }
      if (as < bs) return -1;
      else if (as > bs) return 1;
      return 0;
    });

    return exports;
  };

  /**
   * Get index of export matching the export request object. Does not return an index if multiple exports match.
   * @private
   * @param {ExportRequestObject} ero - Object defining export request
   * @return {Number} index of matching export, undefined if not found
   */
  var getUniqueExportIdxByRequestObject = function(ero) {

    // input sanity check
    if ( ero === undefined || typeof ero !== 'object' )
      return;

    // define settings for search
    var settings = {};
    if ( ero.plugin !== undefined && typeof ero.plugin === 'string' )
      settings.plugin = ero.plugin;

    var idx;

    // search by id and optional plugin
    if ( ero.id !== undefined && typeof ero.id === 'string' ) {
      settings.id = ero.id;
      idx = getUniqueExportIdxByProperties(settings);
      if ( idx !== undefined )
        return idx;
      delete settings.id;
    }

    // search by id or name and optional plugin
    if ( ero.idOrName !== undefined && typeof ero.idOrName === 'string' ) {
      settings.id = ero.idOrName;
      idx = getUniqueExportIdxByProperties(settings);
      if ( idx !== undefined )
        return idx;
      delete settings.id;
      settings.name = ero.idOrName;
      idx = getUniqueExportIdxByProperties(settings);
      if ( idx !== undefined )
        return idx;
      delete settings.name;
    }

    // search by name and optional plugin
    if ( ero._name !== undefined && typeof ero._name === 'string' ) {
      settings._name = ero._name;
      idx = getUniqueExportIdxByProperties(settings);
      if ( idx !== undefined )
        return idx;
    }

    // search by name and optional plugin
    if ( ero.name !== undefined && typeof ero.name === 'string' ) {
      settings.name = ero.name;
      idx = getUniqueExportIdxByProperties(settings);
      if ( idx !== undefined )
        return idx;
    }

    // nothing found
  };

  /**
   * Get export definition uniquely matching the export request object.
   * Does not return an export definition if multiple exports match.
   * @private
   * @param {ExportRequestObject} ero - Object defining export request
   * @return {Object} unique matching export, undefined if not found
   */
  _o.getUniqueExportByRequestObject = function(ero) {
    // get unique export index
    var idx = getUniqueExportIdxByRequestObject(ero);
    if (idx === undefined) return;
    // return copy of export definition
    return GlobalUtils.deepCopy(_exports[idx]);
  };

  /**
   * Export request object
   * @typedef {Object} ExportRequestObject
   * @property {String} [id] - id of export, takes precedence over idOrName and name
   * @property {String} [idOrName] - id or name of export, takes precedence over name
   * @property {String} [name] - name of export, last priority after id and idOrName
   * @property {String} [plugin] - runtime id of plugin the export belongs to
   */

  /**
   * Request an export
   *
   * @public
   * @param {ExportRequestObject} ero - object describing the export to request
   * @param {String|module:MessagingConstants~MessageToken} [token] - Will be used by process messages related to this request, a random token will be created if none is provided.
   * @return {module:PluginConstantsGlobal~RequestExportResults} statuscode, see definition
   */
  _o.requestExport = function(ero, token) {
    var scope = 'ViewerAppExportManager.requestExport';

    token = messagingConstants.makeMessageToken(token);

    var fail = function(res, msg) {
      if (token) {
        // we were called with a token, end the process here by sending a PROCESS_ERROR message
        let m = new MessagePrototype(messagingConstants.messageDataTypes.PROCESS_ERROR, msg, token);
        that.message(messagingConstants.messageTopics.PROCESS, m);
      }
      return res;
    };

    // find export using ero
    var idx = getUniqueExportIdxByRequestObject(ero);
    if ( idx === undefined ) {
      let msg = 'Export not found';
      that.error(scope, msg, ero);
      return fail(pluginConstants.requestExportResults.EXPORT_NOT_FOUND, msg);
    }

    // get export definition
    var def = _exports[idx];

    // collect kvps for all parameters belonging to the plugin
    var kvps = _parameterManager.getParameterValuesForPlugin(def.plugin, true);

    // if parameter values were provided as part of ero, copy them
    if ( ero.parameters && typeof ero.parameters === 'object' ) {
      const params = ero.parameters;
      Object.keys(kvps).forEach( function(p) {
        if (params.hasOwnProperty(p)) {
          kvps[p] = params[p];
        }
      });
    }

    // get plugin
    if ( _pluginManager === undefined || _pluginManager.getPluginByRuntimeId === undefined ) {
      let msg = 'Plugin manager not found';
      that.error(scope, msg);
      return fail(pluginConstants.requestExportResults.PLUGIN_NOT_FOUND, msg);
    }
    var plugin = _pluginManager.getPluginByRuntimeId(def.plugin);
    if ( !plugin ) {
      let msg = 'Plugin ' + def.plugin + ' not found';
      that.error(scope, msg);
      return fail(pluginConstants.requestExportResults.PLUGIN_NOT_FOUND, msg);
    }

    // send a process status message
    if (token) {
      let m = new MessagePrototype(messagingConstants.messageDataTypes.PROCESS_STATUS, {progress: 0}, token);
      that.message(messagingConstants.messageTopics.PROCESS, m);
      let m2 = new MessagePrototype(messagingConstants.messageDataTypes.EXPORT_STATUS, def, token);
      that.message(messagingConstants.messageTopics.EXPORT_STATUS, m2);
    }

    // call requestExport for plugin
    return plugin.requestExport(def.id, kvps, token);
  };

  return _o;
};

module.exports = ViewerAppExportManager;
