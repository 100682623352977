"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ColorParameterComponent = void 0;
var BaseParameterComponent_1 = require("./BaseParameterComponent");
var ColorCustomComponent_1 = require("../custom-components/ColorCustomComponent");
var ColorParameterComponent = /** @class */ (function (_super) {
    __extends(ColorParameterComponent, _super);
    // #region Constructors (1)
    function ColorParameterComponent(api, domManager, queue, container, parameter, colorUtil, editMode, useSpectrum) {
        if (editMode === void 0) { editMode = false; }
        if (useSpectrum === void 0) { useSpectrum = false; }
        var _this = _super.call(this, api, domManager, queue, new ColorCustomComponent_1.ColorCustomComponent(parameter.id, {
            name: parameter.name,
            type: parameter.elementType,
            title: parameter.note,
            value: parameter.defval,
            order: parameter.order,
        }, container, domManager, queue, useSpectrum, colorUtil, editMode), parameter, editMode) || this;
        _this._component.parameterDefinition.update = _this.updateAsync.bind(_this);
        return _this;
    }
    Object.defineProperty(ColorParameterComponent.prototype, "hidden", {
        get: function () {
            return this._component.hidden;
        },
        // #endregion Constructors (1)
        set: function (v) {
            this._component.hidden = v;
            if (this._editMode) {
                if (this.inputComponent) {
                    v ? this.inputComponent.classList.add('sdv-hidden') : this.inputComponent.classList.remove('sdv-hidden');
                }
                var colorPicker = this._component.colorPicker;
                if (colorPicker) {
                    v ? colorPicker.classList.add('sdv-hidden') : colorPicker.classList.remove('sdv-hidden');
                }
            }
            else {
                if (v) {
                    this.destroy();
                }
            }
        },
        enumerable: false,
        configurable: true
    });
    return ColorParameterComponent;
}(BaseParameterComponent_1.BaseParameterComponent));
exports.ColorParameterComponent = ColorParameterComponent;
