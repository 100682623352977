"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModalCustomComponent = void 0;
var BaseCustomComponent_1 = require("./BaseCustomComponent");
var ModalCustomComponent = /** @class */ (function (_super) {
    __extends(ModalCustomComponent, _super);
    // #endregion Properties (3)
    // #region Constructors (1)
    function ModalCustomComponent(id, parameterDefinition, parent, domManager, queue, editMode) {
        if (editMode === void 0) { editMode = false; }
        var _this = _super.call(this, "modal" /* MODAL */, id, parameterDefinition, parent, domManager, queue, editMode) || this;
        _this._inputId = "button_" + _this.id;
        _this._modalId = "shapediver_modal_" + _this.id;
        _this.createElement();
        _this.createInputEvents();
        return _this;
    }
    Object.defineProperty(ModalCustomComponent.prototype, "size", {
        // #endregion Constructors (1)
        // #region Public Accessors (1)
        get: function () {
            return 1 /* QTR_WIDTH */;
        },
        enumerable: false,
        configurable: true
    });
    // #endregion Public Accessors (1)
    // #region Public Methods (1)
    ModalCustomComponent.prototype.destory = function () {
        _super.prototype.destroy.call(this);
        this._modalElem.remove();
    };
    Object.defineProperty(ModalCustomComponent.prototype, "content", {
        get: function () {
            return this._modalElem.innerHTML;
        },
        set: function (v) {
            this._parameterDefinition.modalContent = v;
            this._modalElem.innerHTML = v;
        },
        enumerable: false,
        configurable: true
    });
    // #endregion Public Methods (1)
    // #region Protected Methods (4)
    ModalCustomComponent.prototype.createInput = function () {
        this._inputComponent = this._domManager.createDomElement("button", this._inputId, this._inputContainer, ['uk-button', 'uk-button-default', 'uk-button-small', 'uk-width-expand', 'text-transform-none', 'uk-padding-remove', 'sd-control-button'], { type: 'button', name: this._inputId, 'uk-toggle': "target: #" + this._modalId });
        this._inputComponent.innerHTML = this._parameterDefinition.name;
        this._modalElem = this._domManager.createDomElement("div", this._modalId, document.getElementById("sdv-container"), ["uk-modal-container"], {
            'uk-modal': '', 'container': "#sdv-container"
        });
        this._modalElem.innerHTML = "<div class=\"uk-modal-dialog uk-modal-body\">" + this._parameterDefinition.modalContent + "</div>";
    };
    ModalCustomComponent.prototype.createInputEvents = function () { };
    ModalCustomComponent.prototype.createLabel = function () { };
    ModalCustomComponent.prototype.update = function (v, event) { };
    // #endregion Protected Methods (4)
    ModalCustomComponent.prototype.close = function () {
        UIkit.modal(document.getElementById(this._modalId)).hide();
    };
    ModalCustomComponent.prototype.onOpen = function (cb) {
        UIkit.util.on("#" + this._modalId, 'show', function () { return cb(); });
    };
    return ModalCustomComponent;
}(BaseCustomComponent_1.BaseCustomComponent));
exports.ModalCustomComponent = ModalCustomComponent;
