"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateDefinitions = void 0;
var CreateDefinitions = /** @class */ (function () {
    function CreateDefinitions(three) {
        this.three = three;
    }
    CreateDefinitions.prototype.createAnchorAssetDefinition = function () {
        return {
            id: "anchor",
            group: "anchor_group",
            content: [
                {
                    format: "anchor",
                    data: [
                        {
                            version: "1.0",
                            location: { x: 0, y: 0, z: 0 },
                            format: "light_anchor",
                            data: {
                                text: null
                            }
                        }
                    ]
                }
            ]
        };
    };
    CreateDefinitions.prototype.createGeometryAssetDefinition = function () {
        return {
            id: "geometry",
            group: "geometry_group",
            interactionGroup: "dragGroup",
            interactionMode: "global",
            content: [
                {
                    format: "three",
                    data: {
                        threeObject: null
                    }
                }
            ]
        };
    };
    return CreateDefinitions;
}());
exports.CreateDefinitions = CreateDefinitions;
