"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SDControlsManager = void 0;
var interfaces_1 = require("../../../interfaces/api/apps/sd-controls/interfaces");
/**
 * Controls manager class.
 */
var SDControlsManager = /** @class */ (function () {
    // #endregion Properties (5)
    // #region Constructors (1)
    function SDControlsManager(_api, _sdParametersHandler, _sdSettingsHandler, _sdControlsEditManager, _dependenciesResolver, _controlsEvents, _sdSettings, _groups, _container, _sortManager, _controlsContracts, _parameterLinker, _inputComponentsFactory, _resizeManager, _lightDragControls, _editMode) {
        var _this = this;
        if (_editMode === void 0) { _editMode = false; }
        this._api = _api;
        this._sdParametersHandler = _sdParametersHandler;
        this._sdSettingsHandler = _sdSettingsHandler;
        this._sdControlsEditManager = _sdControlsEditManager;
        this._dependenciesResolver = _dependenciesResolver;
        this._controlsEvents = _controlsEvents;
        this._sdSettings = _sdSettings;
        this._groups = _groups;
        this._container = _container;
        this._sortManager = _sortManager;
        this._controlsContracts = _controlsContracts;
        this._parameterLinker = _parameterLinker;
        this._inputComponentsFactory = _inputComponentsFactory;
        this._resizeManager = _resizeManager;
        this._lightDragControls = _lightDragControls;
        this._editMode = _editMode;
        // #region Properties (5)
        this._parameterControls = [];
        this._settingsControls = [];
        this._userControls = [];
        this._viewerResizedEvent = null;
        this._dependenciesResolved = false;
        this._firstClick = true;
        this._container.parentElement.addEventListener("viewer-resized", function (event) {
            _this.resizeControls(event);
        });
        if (this._sdParametersHandler) {
            this._sdParametersHandler.onParameterBatchRegistered(function (data) { return _this.createShapeDiverParametersAsync(data); });
            this._sdParametersHandler.onExportBatchRegistered(function (data) { return _this.createShapeDiverParametersAsync(data); });
            this._sdParametersHandler.onExportUpdated(function (data) { return _this.updateShapeDiverParameterAsync(data); });
            this._sdParametersHandler.onParameterUpdate(function (data) { return _this.updateShapeDiverParameterAsync(data); });
        }
        else if (this._sdSettingsHandler) {
            this._inputComponentsFactory.onSettingCreated(function (a, b) { return _this.onSettingCreated(a, b); });
            this._controlsEvents.on("clear.lights" /* CLEAR_LIGHTS */, function () {
                _this._lightDragControls.useLightHelpers(false);
                _this._settingsControls.filter(function (x) { return x.type === "light_definition" /* LIGHT_DEFINITION */; }).forEach(function (x) { return x.destroy(); });
            });
            this._controlsEvents.on("add" /* ADD */, function () { return _this._lightDragControls.useLightHelpers(true); });
            this._controlsEvents.on("remove_setting" /* REMOVE_SETTING */, function (id) { return _this.removeSetting(id); });
            this._controlsEvents.on("create_setting" /* CREATE_SETTING */, function (event) {
                _this._sdSettings.settings[event.key] = event.definition;
                _this.createShapediverSettingAsync(event.definition);
            });
            var groupedSettings = {};
            for (var k in this._sdSettings.settings) {
                var setting = this._sdSettings.settings[k];
                if (!groupedSettings[setting.groupId]) {
                    groupedSettings[setting.groupId] = [];
                }
                groupedSettings[setting.groupId].push(setting);
            }
            for (var k in groupedSettings) {
                var settingsList = groupedSettings[k].sort(function (a, b) { return a.order - b.order; });
                settingsList.forEach(function (setting) { return _this.createShapediverSettingAsync(setting); });
            }
            this._groups.headersClick(function (evt) {
                var e_1, _a;
                if (evt == interfaces_1.SettingsGroups.LIGHTS) {
                    // boolean for first click, it's difficult to time lights creation
                    // so this hack created them after click on lighs control
                    var lighsElementComponents = [];
                    if (_this._firstClick) {
                        var lights = Object.values(_this._api.scene.lights.getLightScene().data.lights);
                        try {
                            // keep index to order lights according to scene.
                            for (var lights_1 = __values(lights), lights_1_1 = lights_1.next(); !lights_1_1.done; lights_1_1 = lights_1.next()) {
                                var light = lights_1_1.value;
                                var setting = _this._sdSettings.createLightSetting(light);
                                _this._sdSettings.settings[light.id] = setting;
                                // create component, increment index and add it to light components array
                                var component = _this._inputComponentsFactory.createShapeDiverSetting(setting, _this._inputComponentsFactory.groups.getGroupByIndex(setting.groupId));
                                lighsElementComponents.push(component);
                                _this._controlsEvents.trigger("add" /* ADD */, _this);
                            }
                        }
                        catch (e_1_1) { e_1 = { error: e_1_1 }; }
                        finally {
                            try {
                                if (lights_1_1 && !lights_1_1.done && (_a = lights_1.return)) _a.call(lights_1);
                            }
                            finally { if (e_1) throw e_1.error; }
                        }
                        ;
                        _this._firstClick = false;
                    }
                    _this._lightDragControls.useLightHelpers(true);
                    _this._lightDragControls.zoomAsync();
                }
                else {
                    _this._lightDragControls.useLightHelpers(false);
                    _this._settingsControls.filter(function (x) { return x.type == "light_definition" /* LIGHT_DEFINITION */; })
                        .forEach(function (x) { return x.close(); });
                }
            });
        }
        this.resort();
    }
    // #endregion Constructors (1)
    // #region Public Methods (9)
    /**
     * Creates shapediver parameter controls
     * @param { IShapeDiverParamter } parameterDefinition
     * @return {Promise<string>} - empty string if parameters already exists or is hidden in non edit mode
     */
    SDControlsManager.prototype.createShapeDiverParameterAsync = function (parameterDefinition) {
        return __awaiter(this, void 0, void 0, function () {
            var fresh, control;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.resolveDependenciesAsync()];
                    case 1:
                        _a.sent();
                        fresh = null;
                        fresh = this._api.parameters.get().data.find(function (x) { return x.id === parameterDefinition.id; });
                        if (fresh) {
                            parameterDefinition = fresh;
                            parameterDefinition.isParameter = true;
                        }
                        else {
                            fresh = this._api.exports.get().data.find(function (x) { return x.id === parameterDefinition.id; });
                            if (fresh) {
                                parameterDefinition = fresh;
                                parameterDefinition.isParameter = false;
                            }
                        }
                        if (!this._sdParametersHandler || this._parameterControls.some(function (x) { return x.id == parameterDefinition.id; }))
                            return [2 /*return*/, Promise.resolve("")];
                        control = this._inputComponentsFactory.createShapeDiverParameter(parameterDefinition, this._container, this._editMode);
                        if (!control)
                            return [2 /*return*/, Promise.resolve("")];
                        this._parameterControls.push(control);
                        this._sdControlsEditManager.registerComponent(control);
                        this._sortManager.sort(__spread(this._userControls, this._parameterControls));
                        this.resizeControls();
                        control.onDestroy(function (id) {
                            for (var i = 0; i < _this._parameterControls.length; i++) {
                                if (_this._parameterControls[i].id === id) {
                                    _this._parameterControls.splice(i, 1);
                                    break;
                                }
                            }
                        });
                        return [2 /*return*/, Promise.resolve(control.id)];
                }
            });
        });
    };
    SDControlsManager.prototype.createShapediverSettingAsync = function (settingDefinition) {
        return __awaiter(this, void 0, void 0, function () {
            var control;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.resolveDependenciesAsync()];
                    case 1:
                        _a.sent();
                        if (!this._settingsControls || this._settingsControls.some(function (x) { return x.id == settingDefinition.id; }))
                            return [2 /*return*/, Promise.resolve("")];
                        control = this._inputComponentsFactory.createShapeDiverSetting(settingDefinition, this._groups.getGroupByIndex(settingDefinition.groupId));
                        this.onSettingCreated(control, settingDefinition);
                        return [2 /*return*/, Promise.resolve(control.id)];
                }
            });
        });
    };
    /**
     * Create the input control
     * @param { ControlParameterDefinition } parameterDef
     */
    SDControlsManager.prototype.createUserDefinedParameterAsync = function (parameterDefinition) {
        return __awaiter(this, void 0, void 0, function () {
            var control;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        parameterDefinition = this._parameterLinker.createLinkIfDefined(parameterDefinition);
                        this._controlsContracts.controlParameterDefinitionContract(parameterDefinition);
                        return [4 /*yield*/, this.resolveDependenciesAsync()];
                    case 1:
                        _a.sent();
                        control = this._inputComponentsFactory.createUserDefinedParameter(parameterDefinition, this._container, this._editMode);
                        this._userControls.push(control);
                        this._sortManager.sort(__spread(this._userControls, this._parameterControls));
                        this.resizeControls(null, [control]);
                        return [2 /*return*/, Promise.resolve(control.id)];
                }
            });
        });
    };
    /**
     * Destroys the parameters
     * @return { void }
     */
    SDControlsManager.prototype.destroyParameters = function () {
        this._parameterControls.forEach(function (x) { return x.destroy(); });
        this._userControls.forEach(function (x) { return x.destroy(); });
        this._parameterControls.length = 0;
        this._userControls.length = 0;
    };
    SDControlsManager.prototype.removeSetting = function (id) {
        var _a;
        delete this._sdSettings.settings[id];
        (_a = this._settingsControls.find(function (x) { return x.id === id; })) === null || _a === void 0 ? void 0 : _a.destroy();
    };
    SDControlsManager.prototype.removeShapeDiverParameter = function (id) {
        throw new Error("Method not implemented.");
    };
    /**
     * Removes user defined parameter.
     * @param { string } id
     */
    SDControlsManager.prototype.removeUserDefinedParameter = function (id) {
        return this.removeParameter(this._userControls, id);
    };
    /**
     * Resorts the parameters.
     */
    SDControlsManager.prototype.resort = function () {
        var _this = this;
        // timeoute is bit of optimization, to avoid rerendering of dom
        this._resortTimeout = window.setTimeout(function () {
            if (_this._resortTimeout) {
                window.clearTimeout(_this._resortTimeout);
            }
            _this._sortManager.sort(__spread(_this._userControls, _this._parameterControls));
        }, 500);
    };
    /**
     * Saves the parameters
     * @return { void }
     */
    SDControlsManager.prototype.saveParameters = function () {
        var _this = this;
        if (this._editMode === true) {
            this._parameterControls.forEach(function (pc) {
                var updateObject = {
                    id: pc.id,
                    hidden: pc.hidden,
                    order: pc.order,
                    name: null,
                };
                if (pc.wasEdited && pc.newName !== null && pc.newName !== pc.name) {
                    updateObject.name = pc.newName;
                }
                if (pc.isParameter === true) {
                    _this._api.parameters.updateProperties(updateObject);
                }
                else if (pc.isExport === true) {
                    _this._api.exports.updateProperties(updateObject);
                }
                pc.name = pc.label.innerHTML;
            });
        }
    };
    // #endregion Public Methods (9)
    // #region Private Methods (5)
    /**
     * Creates shapediver parameter controls
     * @param { Array<IShapeDiverParamter> } parametersDefinitions
     * @return {Promise<string>} - empty string if parameters already exists or is hidden in non edit mode
     */
    SDControlsManager.prototype.createShapeDiverParametersAsync = function (parametersDefinitions) {
        return __awaiter(this, void 0, void 0, function () {
            var fresh, _loop_1, this_1, parametersDefinitions_1, parametersDefinitions_1_1, parameterDefinition, state_1;
            var e_2, _a;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.resolveDependenciesAsync()];
                    case 1:
                        _b.sent();
                        fresh = null;
                        _loop_1 = function (parameterDefinition) {
                            fresh = this_1._api.parameters.get().data.find(function (x) { return x.id === parameterDefinition.id; });
                            if (fresh) {
                                parameterDefinition = fresh;
                                parameterDefinition.isParameter = true;
                            }
                            else {
                                fresh = this_1._api.exports.get().data.find(function (x) { return x.id === parameterDefinition.id; });
                                if (fresh) {
                                    parameterDefinition = fresh;
                                    parameterDefinition.isParameter = false;
                                }
                            }
                            if (!this_1._sdParametersHandler || this_1._parameterControls.some(function (x) { return x.id == parameterDefinition.id; }))
                                return "continue";
                            var control = this_1._inputComponentsFactory.createShapeDiverParameter(parameterDefinition, this_1._container, this_1._editMode);
                            if (control == null)
                                return { value: Promise.resolve() };
                            this_1._parameterControls.push(control);
                            this_1._sdControlsEditManager.registerComponent(control);
                            control.onDestroy(function (id) {
                                for (var i = 0; i < _this._parameterControls.length; i++) {
                                    if (_this._parameterControls[i].id === id) {
                                        _this._parameterControls.splice(i, 1);
                                        break;
                                    }
                                }
                            });
                        };
                        this_1 = this;
                        try {
                            for (parametersDefinitions_1 = __values(parametersDefinitions), parametersDefinitions_1_1 = parametersDefinitions_1.next(); !parametersDefinitions_1_1.done; parametersDefinitions_1_1 = parametersDefinitions_1.next()) {
                                parameterDefinition = parametersDefinitions_1_1.value;
                                state_1 = _loop_1(parameterDefinition);
                                if (typeof state_1 === "object")
                                    return [2 /*return*/, state_1.value];
                            }
                        }
                        catch (e_2_1) { e_2 = { error: e_2_1 }; }
                        finally {
                            try {
                                if (parametersDefinitions_1_1 && !parametersDefinitions_1_1.done && (_a = parametersDefinitions_1.return)) _a.call(parametersDefinitions_1);
                            }
                            finally { if (e_2) throw e_2.error; }
                        }
                        this._sortManager.sort(__spread(this._userControls, this._parameterControls));
                        this.resizeControls();
                        return [2 /*return*/, Promise.resolve()];
                }
            });
        });
    };
    SDControlsManager.prototype.onSettingCreated = function (control, defintion) {
        var _this = this;
        // Don't add if not in settings list, search by pattern '_'
        var isInSettingList = Object.keys(this._sdSettings.settings)
            .map(function (x) { return x.lastIndexOf("_") > 0 ? x.substring(0, x.lastIndexOf("_")) : x; })
            .includes(defintion.id);
        var isLightDef = defintion.type == "light_definition" /* LIGHT_DEFINITION */;
        if (!isInSettingList && !isLightDef) {
            return;
        }
        this._settingsControls.push(control);
        var groups = {};
        this._settingsControls.forEach(function (x) { return Array.isArray(groups[x.groupId])
            ? groups[x.groupId].push(x) : groups[x.groupId] = [x]; });
        Object.keys(groups).forEach(function (x) { return _this._sortManager.sort(__spread(groups[x]), true); });
        control.onDestroy(function (id) {
            for (var i = 0; i < _this._settingsControls.length; i++) {
                if (_this._settingsControls[i].id === id) {
                    _this._settingsControls.splice(i, 1);
                    delete _this._sdSettings.settings[id];
                    break;
                }
            }
        });
        this.resizeControls(null, [control]);
    };
    SDControlsManager.prototype.removeParameter = function (controls, id) {
        for (var i = controls.length - 1; i >= 0; i--) {
            var parameter = controls[i];
            if (parameter.children && parameter.children.length > 0) {
                var res = this.removeParameter(parameter.children, id);
                if (res) {
                    return true;
                }
            }
            if (parameter.id === id) {
                controls.splice(i, 1);
                parameter.destroy();
                return true;
            }
        }
        return false;
    };
    SDControlsManager.prototype.resizeControls = function (event, toResize) {
        var _a, _b;
        if (event === void 0) { event = null; }
        if (toResize === void 0) { toResize = null; }
        toResize = toResize !== null && toResize !== void 0 ? toResize : this._sortManager.sort(__spread(this._userControls, this._parameterControls, this._settingsControls), false);
        if (event != null) {
            this._viewerResizedEvent = event;
        }
        this._resizeManager.resize(toResize, (_a = this._viewerResizedEvent) === null || _a === void 0 ? void 0 : _a.detail.mode, (_b = this._viewerResizedEvent) === null || _b === void 0 ? void 0 : _b.detail.clientWidth);
    };
    SDControlsManager.prototype.resolveDependenciesAsync = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(this._dependenciesResolved !== true)) return [3 /*break*/, 2];
                        _a = this;
                        return [4 /*yield*/, this._dependenciesResolver.resolveDependenciesAsync()];
                    case 1:
                        _a._dependenciesResolved = _b.sent();
                        _b.label = 2;
                    case 2: return [2 /*return*/, Promise.resolve()];
                }
            });
        });
    };
    SDControlsManager.prototype.updateShapeDiverParameterAsync = function (parameter) {
        var _this = this;
        var exsists = false;
        this._parameterControls.forEach(function (x) {
            if (x.id === parameter.id) {
                exsists = true;
                if (parameter.name && parameter.name !== x.name) {
                    x.name = parameter.name;
                }
                if (typeof parameter.order === "number" && parameter.order != x.order) {
                    x.order = parameter.order;
                }
                if (_this._editMode && parameter.hidden) {
                    x.hidden = parameter.hidden;
                }
                else if (parameter.hidden) {
                    x.destroy();
                }
            }
        });
        this.resort();
        if (parameter.id) {
            if (exsists == false) {
                if (parameter.hidden === false) {
                    this.createShapeDiverParameterAsync(parameter);
                }
            }
        }
    };
    return SDControlsManager;
}());
exports.SDControlsManager = SDControlsManager;
