"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GltfLoaderTexture = void 0;
// TODO : refactor tetxure loader
var TextureLoader = require("../TextureLoader");
var DATA_URI_REGEX = /^data:(.*?)(;base64)?,(.*)$/;
var HTTPS_URI_REGEX = /^https:\/\//;
var GltfLoaderTexture = /** @class */ (function () {
    // #endregion Properties (5)
    // #region Constructors (1)
    function GltfLoaderTexture(id, gltf, buffers) {
        var _this = this;
        this.id = id;
        this._texture = null;
        this.id = id;
        this.textureRef = gltf.textures[this.id];
        this.imageRef = gltf.images[this.textureRef.source];
        if (this.imageRef.uri) {
            this._onLoad = new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
                var url, tex, error_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            url = DATA_URI_REGEX.test(this.imageRef.uri) || HTTPS_URI_REGEX.test(this.imageRef.uri) ? this.imageRef.uri : gltf.baseUrl + "/" + this.imageRef.uri;
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 3, , 4]);
                            return [4 /*yield*/, TextureLoader.loadTexture(url)];
                        case 2:
                            tex = _a.sent();
                            tex.flipY = false;
                            this._texture = tex;
                            resolve();
                            return [3 /*break*/, 4];
                        case 3:
                            error_1 = _a.sent();
                            console.error(error_1);
                            reject(error_1);
                            return [3 /*break*/, 4];
                        case 4: return [2 /*return*/];
                    }
                });
            }); });
        }
        else if (this.imageRef.bufferView) {
            this._onLoad = new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
                var id_1, gltfBufferView_1, buffer, dataView, array, i, blob, dataUri, tex, error_2;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            id_1 = this.imageRef.bufferView;
                            gltfBufferView_1 = gltf.bufferViews[id_1];
                            buffer = buffers.find(function (x) { return x.id == gltfBufferView_1.buffer; });
                            dataView = buffer.bufferViews.find(function (x) { return x.id == id_1; }).dataView;
                            array = [];
                            for (i = 0; i < dataView.byteLength; i += 1) {
                                array[i] = dataView.getUint8(i);
                            }
                            blob = new Blob([new Uint8Array(array)], { type: this.imageRef.mimeType });
                            dataUri = window.URL.createObjectURL(blob);
                            window.open(dataUri);
                            return [4 /*yield*/, TextureLoader.loadTexture(dataUri)];
                        case 1:
                            tex = _a.sent();
                            tex.flipY = false;
                            this._texture = tex;
                            resolve();
                            return [3 /*break*/, 3];
                        case 2:
                            error_2 = _a.sent();
                            console.error(error_2);
                            reject(error_2);
                            return [3 /*break*/, 3];
                        case 3: return [2 /*return*/];
                    }
                });
            }); });
        }
    }
    Object.defineProperty(GltfLoaderTexture.prototype, "onLoad", {
        // #endregion Constructors (1)
        // #region Public Accessors (2)
        get: function () {
            return this._onLoad;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(GltfLoaderTexture.prototype, "texture", {
        get: function () {
            return this._texture;
        },
        enumerable: false,
        configurable: true
    });
    // #endregion Public Accessors (2)
    // #region Public Methods (1)
    GltfLoaderTexture.prototype.toJSONTexture = function () {
        return {
            href: this.href
        };
    };
    // #endregion Public Methods (1)
    // #region Private Methods (1)
    GltfLoaderTexture.prototype.encodeBase64DataUri = function (buf) {
        var codes = [];
        var uint8arr = new Uint8Array(buf);
        for (var i = 0; i < uint8arr.length; i++) {
            codes.push(String.fromCharCode(uint8arr[i]));
        }
        var b64 = btoa(codes.join(""));
        var uri = "data:application/octet-stream;base64," + b64;
        return uri;
    };
    return GltfLoaderTexture;
}());
exports.GltfLoaderTexture = GltfLoaderTexture;
