var defaultHighlights = [
  // opacity highlight
  {
    apply: function (object, options) {
      if (!object.material) return;
      object.material.opacity *= options.opacity;
      object.material.transparent = true;
    },
    remove: function (object, options) {
      if (!object.material) return;
      object.material.opacity *= 1.0/options.opacity;
      object.material.transparent = false;
    }
  },

  // color hightlight
  {
    apply: function (object, options) {
      if (!(object.material && object.material.color)) return;
      object.material.color.r += options.color.r;
      object.material.color.g += options.color.g;
      object.material.color.b += options.color.b;
    },
    remove: function (object, options) {
      if (!(object.material && object.material.color)) return;
      object.material.color.r -= options.color.r;
      object.material.color.g -= options.color.g;
      object.material.color.b -= options.color.b;
    }
  }
];

module.exports = defaultHighlights;
