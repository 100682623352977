"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DragControlsScenePathHelper = void 0;
var DragControlsScenePathHelper = /** @class */ (function () {
    function DragControlsScenePathHelper() {
    }
    // #region Public Methods (2)
    DragControlsScenePathHelper.prototype.extractLightIdFromPath = function (path) {
        // type_id_property
        var s = path.substring(path.indexOf("_") + 1);
        return s.substring(0, s.lastIndexOf("_"));
    };
    DragControlsScenePathHelper.prototype.extractPropertyFromPath = function (path) {
        // type_id_property
        return path.substring(path.lastIndexOf("_") + 1);
    };
    return DragControlsScenePathHelper;
}());
exports.DragControlsScenePathHelper = DragControlsScenePathHelper;
