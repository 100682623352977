"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.BooleanInputCustomComponent = void 0;
var BaseCustomComponent_1 = require("./BaseCustomComponent");
var BooleanInputCustomComponent = /** @class */ (function (_super) {
    __extends(BooleanInputCustomComponent, _super);
    // #endregion Properties (4)
    // #region Constructors (1)
    function BooleanInputCustomComponent(id, parameterDefinition, parent, domManager, queue, editMode) {
        if (editMode === void 0) { editMode = false; }
        var _this = _super.call(this, "checkbox" /* CHECKBOX */, id, parameterDefinition, parent, domManager, queue, editMode) || this;
        _this._inputId = id + "-checkbox";
        _this._forName = _this.id + "-checkbox";
        _this.createElement();
        _this.createInputEvents();
        return _this;
    }
    Object.defineProperty(BooleanInputCustomComponent.prototype, "boolContainer", {
        // #endregion Constructors (1)
        // #region Public Accessors (4)
        get: function () {
            return this._boolContainer;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BooleanInputCustomComponent.prototype, "inputCheckboxContainer", {
        get: function () {
            return this._inputCheckboxContainer;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BooleanInputCustomComponent.prototype, "size", {
        get: function () {
            return 1 /* QTR_WIDTH */;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BooleanInputCustomComponent.prototype, "value", {
        get: function () {
            return this._inputComponent.checked;
        },
        set: function (v) {
            this._inputComponent.value = v;
            this._inputComponent.checked = this._inputComponent.value === "true";
        },
        enumerable: false,
        configurable: true
    });
    // #endregion Public Accessors (4)
    // #region Public Methods (1)
    BooleanInputCustomComponent.prototype.isCurrentValueSameAsInital = function () {
        return this.value.toString() === this._initalValue.toString();
    };
    // #endregion Public Methods (1)
    // #region Protected Methods (3)
    BooleanInputCustomComponent.prototype.createInput = function () {
        var _a, _b;
        this._boolContainer = this._domManager.createDomElement("label", this.id + "-checkbox-label", this._inputContainer, ['sdv-control-panel-boolean-input-checkbox-label'], { for: this._forName, "data-friendly-name": this._parameterDefinition.name });
        this._inputComponent = this._domManager.createDomElement("input", this._inputId, this._boolContainer, ["shapediver-viewer-element", "sdv-control-panel-control", "sdv-control-panel-boolean-input-checkbox"], { type: "checkbox", name: this._forName });
        this._inputCheckboxContainer = this._domManager.createDomElement("div", this.id + "-checkbox-helper", this._boolContainer, ['sdv-control-panel-boolean-input-boxhelper'], { for: this._forName });
        this._inputComponent.value = (_b = (_a = this === null || this === void 0 ? void 0 : this._parameterDefinition) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : "false";
        this._inputComponent.checked = this._inputComponent.value === "true";
    };
    BooleanInputCustomComponent.prototype.createInputEvents = function () {
        this._domManager.setDomEventListener(this._inputComponent, "change", 10, this.update.bind(this));
    };
    BooleanInputCustomComponent.prototype.update = function (v, event) {
        _super.prototype.update.call(this, event.srcElement.checked, event);
    };
    return BooleanInputCustomComponent;
}(BaseCustomComponent_1.BaseCustomComponent));
exports.BooleanInputCustomComponent = BooleanInputCustomComponent;
