"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FPSManager = void 0;
var FPSLogic_1 = require("./FPSLogic");
var FPSManager = /** @class */ (function () {
    // #endregion Properties (4)
    // #region Constructors (1)
    function FPSManager(_settings, _cameraControls, _triggerRender, _logWarning) {
        var _this = this;
        this._settings = _settings;
        this._cameraControls = _cameraControls;
        this._triggerRender = _triggerRender;
        this._logWarning = _logWarning;
        // #region Properties (4)
        this._activeLock = false;
        this._keysPressed = [];
        this._storedSettings = {};
        this._cameraLogic = new FPSLogic_1.FPSLogic(this._cameraControls);
        document.addEventListener('pointerlockchange', function (e) {
            _this._activeLock = !_this._activeLock;
        }, false);
        document.addEventListener('keyup', function (e) {
            var i = _this._keysPressed.indexOf(e.key.toLowerCase());
            if (i > -1) {
                _this._keysPressed.splice(i, 1);
                _this._cameraLogic.move(_this._keysPressed);
            }
        });
        this._cameraControls.registerCameraControls(this);
    }
    // #endregion Constructors (1)
    // #region Public Methods (12)
    FPSManager.prototype.isWithinRestrictions = function (position, target) {
        return true;
    };
    FPSManager.prototype.onKeyDown = function (event) {
        var i = this._keysPressed.indexOf(event.key.toLowerCase());
        if (i === -1) {
            this._keysPressed.push(event.key.toLowerCase());
            this._cameraLogic.move(this._keysPressed);
        }
    };
    FPSManager.prototype.onMouseDown = function (event) {
        if (event.srcElement instanceof Element) {
            if (this._activeLock === false) {
                event.srcElement.requestPointerLock();
            }
            else {
                document.exitPointerLock();
            }
        }
    };
    FPSManager.prototype.onMouseMove = function (event) {
        if (this._activeLock === true) {
            this._cameraLogic.rotate(event.movementX, event.movementY);
        }
    };
    FPSManager.prototype.onMouseUp = function (event) { };
    FPSManager.prototype.onMouseWheel = function (event) { };
    FPSManager.prototype.onTouchEnd = function (event) { };
    FPSManager.prototype.onTouchMove = function (event) { };
    FPSManager.prototype.onTouchStart = function (event) { };
    FPSManager.prototype.reset = function () {
        this._keysPressed = [];
        this._cameraLogic.reset();
    };
    FPSManager.prototype.restrict = function (position, target) {
        return { position: position, target: target };
    };
    FPSManager.prototype.update = function (time) {
        this._cameraLogic.update(time);
    };
    return FPSManager;
}());
exports.FPSManager = FPSManager;
