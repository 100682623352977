"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileUtilInstance = void 0;
var FileUtil = /** @class */ (function () {
    function FileUtil() {
    }
    // #region Public Methods (1)
    /**
   * Try to guess a mime type from a file name
   * @param {String} filename
   * @return {String} guessed mime type, empty string in case none could be guessed
   */
    FileUtil.prototype.guessMimeTypeFromFilename = function (filename) {
        var name = filename.toLowerCase();
        if (name.endsWith('.dxf')) {
            return 'application/dxf';
        }
        else if (name.endsWith('.obj')) {
            return 'application/wavefront-obj';
        }
        else if (name.endsWith('.csv')) {
            return 'text/csv';
        }
        return '';
    };
    return FileUtil;
}());
exports.FileUtilInstance = new FileUtil();
