"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiLightHelperWrapper = void 0;
var ApiLightHelperWrapper = /** @class */ (function () {
    // #endregion Properties (1)
    // #region Constructors (1)
    function ApiLightHelperWrapper(api) {
        this.api = api;
    }
    // #endregion Constructors (1)
    // #region Public Methods (20)
    ApiLightHelperWrapper.prototype.addAnchorAddListener = function (callback) {
        this.api.scene.addEventListener(this.api.scene.EVENTTYPE.ANCHOR_ADD, callback);
    };
    ApiLightHelperWrapper.prototype.addAnchorRemoveListener = function (callback) {
        this.api.scene.addEventListener(this.api.scene.EVENTTYPE.ANCHOR_REMOVE, callback);
    };
    ApiLightHelperWrapper.prototype.addDragEndListener = function (callback) {
        this.api.scene.addEventListener(this.api.scene.EVENTTYPE.DRAG_END, callback);
    };
    ApiLightHelperWrapper.prototype.addDragMoveListener = function (callback) {
        this.api.scene.addEventListener(this.api.scene.EVENTTYPE.DRAG_MOVE, callback);
    };
    ApiLightHelperWrapper.prototype.addDragStartListener = function (callback) {
        this.api.scene.addEventListener(this.api.scene.EVENTTYPE.DRAG_START, callback);
    };
    ApiLightHelperWrapper.prototype.appendElementToVieweport = function (viewport, elem) {
        this.api.viewports
            .get(viewport)
            .getContainer()
            .appendChild(elem);
    };
    ApiLightHelperWrapper.prototype.convertTo2D = function (vec) {
        return this.api.scene.convertTo2D(vec).data[0];
    };
    ApiLightHelperWrapper.prototype.createInteractionsGroup = function () {
        return this.api.scene.updateInteractionGroups([
            {
                id: "dragGroup",
                selectable: false,
                draggable: true,
                hoverable: false
            }
        ]);
    };
    ApiLightHelperWrapper.prototype.getLight = function (id) {
        return this.api.scene.lights.getLight(id).data;
    };
    ApiLightHelperWrapper.prototype.getLightScene = function () {
        return this.api.scene.lights.getLightScene().data;
    };
    ApiLightHelperWrapper.prototype.getLightHelpersVisible = function () {
        return this.lightHelpersVisible;
    };
    ApiLightHelperWrapper.prototype.getSceneBoundingBox = function () {
        return this.api.scene.getBoundingBox().data;
    };
    ApiLightHelperWrapper.prototype.isDirectionalLight = function (lightDef) {
        return lightDef.type === this.api.scene.lights.LIGHTTYPE.DIRECTIONAL;
    };
    ApiLightHelperWrapper.prototype.isPointLight = function (lightDef) {
        return lightDef.type === this.api.scene.lights.LIGHTTYPE.POINT;
    };
    ApiLightHelperWrapper.prototype.isSpotLight = function (lightDef) {
        return lightDef.type === this.api.scene.lights.LIGHTTYPE.SPOT;
    };
    ApiLightHelperWrapper.prototype.removeAsync = function (object) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.api.scene.removeAsync(object, "lightHelpers")];
            });
        });
    };
    ApiLightHelperWrapper.prototype.setLightHelpers = function (val) {
        this.api.updateSettingAsync("scene.lights.helper", val);
        this.lightHelpersVisible = val;
    };
    ApiLightHelperWrapper.prototype.toggleGeometry = function (p) {
        this.api.scene.toggleGeometry([], p);
    };
    ApiLightHelperWrapper.prototype.updateAsync = function (assets) {
        return __awaiter(this, void 0, void 0, function () {
            var cameraSetting, autoAdjust, promise, token_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        cameraSetting = 'scene.camera.autoAdjust';
                        autoAdjust = this.api.getSetting(cameraSetting);
                        if (!autoAdjust) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.api.updateSettingAsync(cameraSetting, false)];
                    case 1:
                        _a.sent();
                        promise = this.api.scene.updateAsync(assets, "lightHelpers");
                        token_1 = this.api.scene.addEventListener(this.api.scene.EVENTTYPE.SUBSCENE_PUBLISHED, function () {
                            this.api.updateSettingAsync(cameraSetting, true);
                            this.api.scene.removeEventListener(token_1.data[0]);
                        });
                        return [2 /*return*/, promise];
                    case 2: return [2 /*return*/, this.api.scene.updateAsync(assets, "lightHelpers")];
                }
            });
        });
    };
    ApiLightHelperWrapper.prototype.updateLight = function (light) {
        return this.api.scene.lights.updateLight(light).data;
    };
    ApiLightHelperWrapper.prototype.zoomAsync = function (bb) {
        this.api.scene.camera.zoomAsync(bb);
    };
    return ApiLightHelperWrapper;
}());
exports.ApiLightHelperWrapper = ApiLightHelperWrapper;
