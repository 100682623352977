"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SDFileHelper = void 0;
var SDFileHelper = /** @class */ (function () {
    function SDFileHelper() {
    }
    // #region Public Methods (1)
    SDFileHelper.prototype.guessMimeTypeFromFilename = function (filename) {
        var name = filename.toLowerCase(), type = '';
        if (name.endsWith('.dxf')) {
            type = 'application/dxf';
        }
        else if (name.endsWith('.obj')) {
            type = 'application/wavefront-obj';
        }
        else if (name.endsWith('.csv')) {
            type = 'text/csv';
        }
        return type;
    };
    return SDFileHelper;
}());
exports.SDFileHelper = SDFileHelper;
