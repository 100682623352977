"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SDControlsSortManagerInstance = void 0;
var SDControlsSortManager = /** @class */ (function () {
    function SDControlsSortManager() {
    }
    // #region Public Methods (1)
    /**
     * Sorts the elements in ascending order.
     * @param { ISortableComponent[] }elements
     * @return { Array<T> } - T is casting shorthand
     */
    SDControlsSortManager.prototype.sort = function (elements, uichange) {
        if (uichange === void 0) { uichange = true; }
        if (elements.length > 0) {
            elements.sort(function (a, b) {
                if (a.isExport || b.isExport) {
                    if (a.isExport) {
                        return 1;
                    }
                    else if (b.isExport) {
                        return -1;
                    }
                }
                return a.order - b.order;
            });
            var index = elements.length - 1;
            var current = elements[index];
            // single element case
            if (index === 0) {
                if (current.children && current.children.length > 0) {
                    this.sort(current.children);
                }
            }
            else {
                while (index > 0) {
                    if (current.children && current.children.length > 0) {
                        this.sort(current.children);
                    }
                    var next = elements[--index];
                    if (uichange === true) {
                        next.insertBefore(current);
                    }
                    current = next;
                }
            }
        }
        return elements;
    };
    return SDControlsSortManager;
}());
exports.SDControlsSortManagerInstance = new SDControlsSortManager();
