"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SliderParameterComponent = void 0;
var BaseParameterComponent_1 = require("./BaseParameterComponent");
var SliderCustomComponent_1 = require("../custom-components/SliderCustomComponent");
function getStep(parameter) {
    if (typeof parameter.decimalplaces === "number" || typeof parameter.decimalplaces === "string") {
        switch (parameter.type) {
            case "Even":
            case "Odd":
                return 2;
            case "Int":
                return 0;
            default:
                return Math.pow(0.1, parameter.decimalplaces).toFixed(parameter.decimalplaces);
        }
    }
    return 0;
}
var SliderParameterComponent = /** @class */ (function (_super) {
    __extends(SliderParameterComponent, _super);
    // #region Constructors (1)
    function SliderParameterComponent(api, domManager, queue, container, parameter, editMode) {
        if (editMode === void 0) { editMode = false; }
        var _this = _super.call(this, api, domManager, queue, new SliderCustomComponent_1.SliderCustomComponent(parameter.id, {
            name: parameter.name,
            type: parameter.elementType,
            min: parameter.min,
            max: parameter.max,
            title: parameter.note,
            value: parameter.defval,
            delay: [0, 1000],
            order: parameter.order,
            step: getStep(parameter)
        }, container, domManager, queue), parameter, editMode) || this;
        if (parameter.decimalplaces) {
            _this._component.decimalPlaces = Number(parameter.decimalplaces);
        }
        _this._component.parameterDefinition.update = _this.updateAsync.bind(_this);
        return _this;
    }
    Object.defineProperty(SliderParameterComponent.prototype, "hidden", {
        // #endregion Constructors (1)
        // #region Public Accessors (2)
        get: function () {
            return this._component.hidden;
        },
        set: function (v) {
            this._component.hidden = v;
            if (this._editMode) {
                if (this.inputComponent) {
                    v ? this.inputComponent.classList.add('sdv-hidden') : this.inputComponent.classList.remove('sdv-hidden');
                }
                if (this._component.inputRange) {
                    var inputRange = this._component.inputRange;
                    v ? inputRange.classList.add('sdv-hidden') : inputRange.classList.remove('sdv-hidden');
                }
            }
            else {
                if (v) {
                    this.destroy();
                }
            }
        },
        enumerable: false,
        configurable: true
    });
    return SliderParameterComponent;
}(BaseParameterComponent_1.BaseParameterComponent));
exports.SliderParameterComponent = SliderParameterComponent;
