"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GltfLoaderBuffer = void 0;
var interfaces_1 = require("../../../modules/http-client/interfaces");
var BufferView_1 = require("./BufferView");
var DATA_URI_REGEX = /^data:(.*?)(;base64)?,(.*)$/;
var GltfLoaderBuffer = /** @class */ (function () {
    // #endregion Properties (5)
    // #region Constructors (1)
    function GltfLoaderBuffer(id, gltf) {
        var _this = this;
        this.id = id;
        this._buffer = null;
        this.bufferViews = [];
        this.bufferRef = gltf.buffers[id];
        this.byteLength = this.bufferRef.byteLength;
        this._onLoad = new Promise(function (resolve, reject) {
            if (DATA_URI_REGEX.test(_this.bufferRef.uri)) {
                // to array buffer
                var dataParts = DATA_URI_REGEX.exec(_this.bufferRef.uri);
                _this.buffer = _this.decodeDataUri(dataParts, interfaces_1.ResponseType.ArrayBuffer);
                _this.createBufferViews(gltf);
                resolve();
            }
            // GLB-stored Buffer, doesn't have an uri and must be the first one, see https://github.com/KhronosGroup/glTF/blob/master/specification/2.0/README.md#glb-stored-buffer
            else if (id == 0 && !_this.bufferRef.uri) {
                var length_1 = gltf.dataView.byteLength;
                var buffer = new ArrayBuffer(length_1);
                var view = new Uint8Array(buffer);
                for (var i = 0; i < gltf.dataView.byteLength; i++) {
                    view[i] = gltf.dataView.getUint8(i);
                }
                _this.buffer = buffer;
                _this.createBufferViews(gltf);
                resolve();
            }
            else {
                var url = gltf.baseUrl + "/" + _this.bufferRef.uri;
                _this.loadArrayBuffer(url, function (promise) {
                    // to array buffer
                    _this.buffer = promise;
                    _this.createBufferViews(gltf);
                    resolve();
                }, function (err) {
                    console.error(err);
                    reject(err);
                });
            }
        });
    }
    Object.defineProperty(GltfLoaderBuffer.prototype, "buffer", {
        // #endregion Constructors (1)
        // #region Public Accessors (3)
        get: function () {
            return this._buffer;
        },
        set: function (val) {
            this._buffer = val;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(GltfLoaderBuffer.prototype, "onLoad", {
        get: function () {
            return this._onLoad;
        },
        enumerable: false,
        configurable: true
    });
    // #endregion Public Accessors (3)
    // #region Private Methods (6)
    GltfLoaderBuffer.prototype.createBufferViews = function (gltf) {
        // buffer views
        for (var i in gltf.bufferViews) {
            if (gltf.bufferViews[i].buffer == this.id) {
                this.bufferViews.push(new BufferView_1.BufferView(Number.parseInt(i), gltf, this));
            }
        }
    };
    GltfLoaderBuffer.prototype.decodeDataUri = function (dataUriRegexResult, responseType) {
        responseType = typeof responseType !== 'undefined' ? responseType : interfaces_1.ResponseType.Empty;
        var mimeType = dataUriRegexResult[1];
        var isBase64 = !!dataUriRegexResult[2];
        var data = dataUriRegexResult[3];
        switch (responseType) {
            case interfaces_1.ResponseType.Empty:
            case interfaces_1.ResponseType.Text:
                return this.decodeDataUriText(isBase64, data);
            case interfaces_1.ResponseType.ArrayBuffer:
                return this.decodeDataUriArrayBuffer(isBase64, data);
            case interfaces_1.ResponseType.Blob: {
                var buffer = this.decodeDataUriArrayBuffer(isBase64, data);
                return new Blob([buffer], {
                    type: mimeType
                });
            }
            case interfaces_1.ResponseType.Document: {
                var parser = new DOMParser();
                return parser.parseFromString(this.decodeDataUriText(isBase64, data), mimeType);
            }
            case interfaces_1.ResponseType.JSON:
                return JSON.parse(this.decodeDataUriText(isBase64, data));
            default:
                throw 'Unhandled responseType: ' + responseType;
        }
    };
    GltfLoaderBuffer.prototype.decodeDataUriArrayBuffer = function (isBase64, data) {
        var byteString = this.decodeDataUriText(isBase64, data);
        var buffer = new ArrayBuffer(byteString.length);
        var view = new Uint8Array(buffer);
        for (var i = 0; i < byteString.length; i++) {
            view[i] = byteString.charCodeAt(i);
        }
        return buffer;
    };
    GltfLoaderBuffer.prototype.decodeDataUriText = function (isBase64, data) {
        var result = decodeURIComponent(data);
        if (isBase64) {
            return atob(result);
        }
        return result;
    };
    GltfLoaderBuffer.prototype.loadArrayBuffer = function (url, success, error) {
        this.loadWithXhr(url, 'arraybuffer', success, error);
    };
    GltfLoaderBuffer.prototype.loadWithXhr = function (url, responseType, success, error) {
        var dataUriRegexResult = DATA_URI_REGEX.exec(url);
        if (dataUriRegexResult !== null) {
            success(this.decodeDataUri(dataUriRegexResult, responseType));
            return;
        }
        var xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        if (typeof responseType !== 'undefined') {
            xhr.responseType = responseType;
        }
        xhr.onload = function (e) {
            if (xhr.status === 200) {
                success(xhr.response);
            }
            else {
                error(e);
            }
        };
        xhr.onerror = function (e) {
            error(e);
        };
        xhr.send();
    };
    return GltfLoaderBuffer;
}());
exports.GltfLoaderBuffer = GltfLoaderBuffer;
