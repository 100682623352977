"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FPSLogic = void 0;
var THREE = require("../../../../../../externals/three");
var FPSLogic = /** @class */ (function () {
    // #endregion Properties (1)
    // #region Constructors (1)
    function FPSLogic(_controls) {
        this._controls = _controls;
        // #region Properties (1)
        this._keysPressed = [];
    }
    // #endregion Constructors (1)
    // #region Public Methods (4)
    FPSLogic.prototype.move = function (keysPressed) {
        this._keysPressed = keysPressed;
        this._controls.applyPositionMatrix(new THREE.Matrix4(), true);
        this._controls.applyTargetMatrix(new THREE.Matrix4(), true);
    };
    FPSLogic.prototype.reset = function () {
        this._keysPressed = [];
    };
    FPSLogic.prototype.rotate = function (x, y) {
        var cameraDirection = new THREE.Vector3();
        this._controls.camera.getWorldDirection(cameraDirection);
        cameraDirection.normalize();
        var cross = new THREE.Vector3().crossVectors(this._controls.camera.up, cameraDirection).normalize();
        var up = new THREE.Vector3().crossVectors(cross, cameraDirection).normalize();
        var p = this._controls.position;
        var t = this._controls.target;
        var dist = p.distanceTo(t);
        var dir = cameraDirection.clone();
        dir.applyAxisAngle(up, x * 0.1 * (Math.PI / 180));
        dir.applyAxisAngle(cross, y * 0.1 * (Math.PI / 180));
        var newT = p.clone().add(dir.multiplyScalar(dist));
        var offset = newT.clone().sub(t);
        this._controls.applyTargetMatrix(new THREE.Matrix4().makeTranslation(offset.x, offset.y, offset.z), true);
    };
    FPSLogic.prototype.update = function (time) {
        var x = 0, y = 0, z = 0;
        if (this._keysPressed.indexOf('w') !== -1) {
            y++;
        }
        if (this._keysPressed.indexOf('a') !== -1) {
            x++;
        }
        if (this._keysPressed.indexOf('s') !== -1) {
            y--;
        }
        if (this._keysPressed.indexOf('d') !== -1) {
            x--;
        }
        if (this._keysPressed.indexOf('shift') !== -1) {
            z--;
        }
        if (this._keysPressed.indexOf('control') !== -1) {
            z++;
        }
        if ((x !== 0 || y !== 0 || z !== 0) && time) {
            var timeMagic = time / 16.6666;
            var cameraDirection = new THREE.Vector3();
            this._controls.camera.getWorldDirection(cameraDirection);
            cameraDirection.normalize();
            var cross = new THREE.Vector3().crossVectors(this._controls.camera.up, cameraDirection).normalize();
            var up = new THREE.Vector3().crossVectors(cross, cameraDirection).normalize();
            var offset = cameraDirection.clone().multiplyScalar(timeMagic * y * 0.5);
            offset.add(cross.clone().multiplyScalar(timeMagic * x * 0.5));
            offset.add(up.clone().multiplyScalar(timeMagic * z * 0.5));
            this._controls.applyPositionMatrix(new THREE.Matrix4().makeTranslation(offset.x, offset.y, offset.z), true);
            this._controls.applyTargetMatrix(new THREE.Matrix4().makeTranslation(offset.x, offset.y, offset.z), true);
        }
    };
    return FPSLogic;
}());
exports.FPSLogic = FPSLogic;
