"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var GroupSettings_1 = require("./sd-controls/settings/GroupSettings");
var app_1 = require("../../modules/shapediver-lights-drag-controls/app");
var SDControlsManager_1 = require("./sd-controls/SDControlsManager");
var SDControlsDomManager_1 = require("./sd-controls/managers/SDControlsDomManager");
var SDControlsDependenciesResolver_1 = require("./sd-controls/managers/SDControlsDependenciesResolver");
var SDFileHelper_1 = require("../../modules/shapediver-file-helper/SDFileHelper");
var GenericEvent_1 = require("../../modules/shapediver-generic-event/GenericEvent");
var SDControlComponentContracts_1 = require("./sd-controls/managers/SDControlComponentContracts");
var enums_1 = require("./sd-controls/enums/enums");
var SDControlsParameterHandler_1 = require("./sd-controls/managers/SDControlsParameterHandler");
var TinyColorUtil_1 = require("../../shared/util/TinyColorUtil");
var SDParameterCommitQueue_1 = require("./sd-controls/commit-queues/SDParameterCommitQueue");
var SDControlsEditManager_1 = require("./sd-controls/managers/SDControlsEditManager");
var SDControlsParametersLinker_1 = require("./sd-controls/managers/SDControlsParametersLinker");
var SDControlsSettings_1 = require("./sd-controls/settings/SDControlsSettings");
var SDControlsResizeManager_1 = require("./sd-controls/managers/SDControlsResizeManager");
var SDControlsComponentFactory_1 = require("./sd-controls/managers/SDControlsComponentFactory");
var SDControlsSortManager_1 = require("./sd-controls/managers/SDControlsSortManager");
var SDControlsSettingsHandler_1 = require("./sd-controls/managers/SDControlsSettingsHandler");
/**
 * Creates the controls in the given container.
 * Currently it is possible to create either controls for all parameters or for specified settings.
 *
 * @param {Object} api The api that is needed
 * @param {Object} container The container dom in which to put the controls
 * @param {Object} [options] Options that can be given, setting options.settings to true makes this a controls element for the settings
 * @param {Object} [options.settings] If true, these are the controls for the settings
 * @param {Object} [options.editMode] If true, and this is not a settings object, the controls are made in edit mode
 * @param {Object} [options.commitParameter] If true, parameter validation is enabled.
 * @param {Function} [options.showMessage] If defined, use this callback for displaying messages to the user
 */
var ShapeDiverControls = /** @class */ (function () {
    // #endregion Properties (20)
    // #region Constructors (1)
    function ShapeDiverControls(_api, _parentContainer, _options, _events) {
        var _a;
        this._api = _api;
        this._parentContainer = _parentContainer;
        this._options = _options;
        this._events = _events;
        this._initializeDone = null;
        this._editMode = !!(this._options && (this._options.editMode === true || this._options.editMode === "true"));
        this._domManager = new SDControlsDomManager_1.SDControlsDomManager();
        this._sdControlsDomManager = new SDControlsDomManager_1.SDControlsDomManager();
        this._sdFileHelper = new SDFileHelper_1.SDFileHelper();
        this._sdControlsDomManager = new SDControlsDomManager_1.SDControlsDomManager();
        this._sdDepenenciesResolver = new SDControlsDependenciesResolver_1.SDControlsDependenciesResolver(this._sdControlsDomManager.useSpectrum);
        this._sortManager = SDControlsSortManager_1.SDControlsSortManagerInstance;
        this._sdControlsContract = SDControlComponentContracts_1.SDControlComponentsContractsInstance;
        this._sdParametersLinker = new SDControlsParametersLinker_1.SDControlsParameterLinker(this._api);
        this._resizeManager = new SDControlsResizeManager_1.SDControlsResizeManager(this._sdControlsDomManager.userAgentIsMobileDevice);
        this.initializeAsync();
        (_a = this._events) === null || _a === void 0 ? void 0 : _a.onElementCreated();
        if (!this._options.settings) {
            this._sdCommitQueue.use = this._options.commitParameter;
            this._api.updateSettingAsync("commitParameters", true);
        }
    }
    Object.defineProperty(ShapeDiverControls.prototype, "parameterTypes", {
        // #endregion Constructors (1)
        // #region Public Accessors (2)
        get: function () {
            return enums_1.DefaultControlParameterType;
        },
        enumerable: false,
        configurable: true
    });
    // #endregion Public Accessors (2)
    // #region Public Methods (5)
    ShapeDiverControls.prototype.createParameter = function (parameterDef) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._initializeDone];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, this._controlsManager.createUserDefinedParameterAsync(parameterDef)];
                }
            });
        });
    };
    ShapeDiverControls.prototype.destroy = function () {
        this._controlsManager.destroyParameters();
    };
    ShapeDiverControls.prototype.removeParameter = function (id) {
        return this._controlsManager.removeUserDefinedParameter(id);
    };
    ShapeDiverControls.prototype.resort = function () {
        this._controlsManager.resort();
    };
    ShapeDiverControls.prototype.save = function () {
        this._controlsManager.saveParameters();
    };
    // #endregion Public Methods (5)
    // #region Private Methods (4)
    ShapeDiverControls.prototype.createContainer = function () {
        var container = this._domManager.createDomElement("div", "sd-elements-container-" + (this._options.settings ? 'settings' : 'parameters'), null, ["sdv-control-panel-row-container", "uk-child-width-1-1", "uk-margin-remove"], {
            "uk-grid": "",
            "uk-height-match": ""
        });
        this._parentContainer.appendChild(container);
        return container;
    };
    ShapeDiverControls.prototype.initalizeParametersAsync = function () {
        return __awaiter(this, void 0, void 0, function () {
            var headerButton, rowContainer;
            return __generator(this, function (_a) {
                this._sdParametersHandler = new SDControlsParameterHandler_1.SDControlsParameterHandler(this._api, this._sdDepenenciesResolver);
                headerButton = this._domManager.createDomElement("button", this._api.getRuntimeId() + "_sd-control-panel-main-accordion-settings", this._parentContainer, ["sd-control-panel-accordion"]);
                rowContainer = this._domManager.createDomElement("div", this._api.getRuntimeId() + "_settings-text", headerButton, ["shapediver-viewer-element", "sd-control-panel-accordion-text"]);
                rowContainer.innerHTML = "MODEL PARAMETERS";
                this._container = this.createContainer();
                this._controlsEvents = new GenericEvent_1.GenericEvent(["parameter_registered" /* REGISTERED */]);
                this._sdCommitQueue = new SDParameterCommitQueue_1.SDParametersCommitQueue(this._api, this._container.parentElement, this._options.commitParameter);
                this._sdControlsEditManager = new SDControlsEditManager_1.SDControlsEditManager(!!this._options.editMode, SDControlsSortManager_1.SDControlsSortManagerInstance);
                return [2 /*return*/, Promise.resolve(rowContainer)];
            });
        });
    };
    ShapeDiverControls.prototype.initializeAsync = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                if (this._initializeDone === null) {
                    this._initializeDone = new Promise(function (resolve) { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!this._options.settings) return [3 /*break*/, 2];
                                    return [4 /*yield*/, this.initializeSettingAsync()];
                                case 1:
                                    _a.sent();
                                    return [3 /*break*/, 4];
                                case 2: return [4 /*yield*/, this.initalizeParametersAsync()];
                                case 3:
                                    _a.sent();
                                    _a.label = 4;
                                case 4:
                                    this._inputComponentsFactory = new SDControlsComponentFactory_1.SDControlsComponentFactory(this._api, this._sdCommitQueue, this._domManager, TinyColorUtil_1.TinyColorUtilInstance, this._controlsEvents, this._sdFileHelper, this._sdSettings, this._settingsGroups, this._dragControls);
                                    this._controlsManager = new SDControlsManager_1.SDControlsManager(this._api, this._sdParametersHandler, this._sdSettingsHandler, this._sdControlsEditManager, this._sdDepenenciesResolver, this._controlsEvents, this._sdSettings, this._settingsGroups, this._container, this._sortManager, this._sdControlsContract, this._sdParametersLinker, this._inputComponentsFactory, this._resizeManager, this._dragControls, this._editMode);
                                    resolve();
                                    return [2 /*return*/];
                            }
                        });
                    }); });
                    return [2 /*return*/, this._initializeDone];
                }
                return [2 /*return*/, this._initializeDone];
            });
        });
    };
    ShapeDiverControls.prototype.initializeSettingAsync = function () {
        return __awaiter(this, void 0, void 0, function () {
            var headerButton, rowContainer;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._controlsEvents = new GenericEvent_1.GenericEvent(["add" /* ADD */, "clear.lights" /* CLEAR_LIGHTS */, "removed" /* REMOVE */, "dropdown.customUpdate" /* DROPDOWN_CUSTOM_UPDATE */, "remove_setting" /* REMOVE_SETTING */, "create_setting" /* CREATE_SETTING */]);
                        this._sdSettings = new SDControlsSettings_1.SDControlsSettings(this._api, this._editMode, this._options.commitParameter);
                        // @ts-ignore
                        this._dragControls = new app_1.ShapeDiverDragControls(this._api, THREE);
                        this._sdSettingsHandler = new SDControlsSettingsHandler_1.SDControlsSettingsHandler(this._api);
                        return [4 /*yield*/, this._sdDepenenciesResolver.resolveDependenciesAsync()];
                    case 1:
                        _a.sent();
                        headerButton = this._domManager.createDomElement("button", this._api.getRuntimeId() + "_sd-control-panel-main-accordion-settings", this._parentContainer, ["sd-control-panel-accordion"]);
                        rowContainer = this._domManager.createDomElement("div", this._api.getRuntimeId() + "_settings-text", headerButton, ["shapediver-viewer-element", "sd-control-panel-accordion-text"]);
                        rowContainer.innerHTML = "VIEWER SETTINGS";
                        this._container = this.createContainer();
                        this._settingsGroups = new GroupSettings_1.GroupSettings(this._container, this._domManager);
                        return [2 /*return*/, Promise.resolve()];
                }
            });
        });
    };
    return ShapeDiverControls;
}());
/**
 * Creates the controls in the given container.
 * Currently it is possible to create either controls for all parameters or for specified settings.
 *
 * @param {Object} api The api that is needed
 * @param {Object} container The container dom in which to put the controls
 * @param {Object} [options] Options that can be given, setting options.settings to true makes this a controls element for the settings
 * @param {Object} [options.settings] If true, these are the controls for the settings
 * @param {Object} [options.editMode] If true, and this is not a settings object, the controls are made in edit mode
 * @param {Object} [options.commitParameter] If true, parameter validation is enabled.
 * @param {Function} [options.showMessage] If defined, use this callback for displaying messages to the user
 */
function SDControls(api, container, options, events) {
    var controls = new ShapeDiverControls(api, container, options, events);
    return {
        parameterTypes: controls.parameterTypes,
        createParameter: function (parameterDef) { return controls.createParameter(parameterDef); },
        removeParameter: function (id) { return controls.removeParameter(id); },
        save: function () { return controls.save(); },
        destroy: function () { return controls.destroy(); }
    };
}
exports.default = SDControls;
