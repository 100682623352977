"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.StringInputParameterComponent = void 0;
var BaseParameterComponent_1 = require("./BaseParameterComponent");
var StringInputCustomComponent_1 = require("../custom-components/StringInputCustomComponent");
var StringInputParameterComponent = /** @class */ (function (_super) {
    __extends(StringInputParameterComponent, _super);
    function StringInputParameterComponent(api, domManager, queue, container, parameter, editMode) {
        if (editMode === void 0) { editMode = false; }
        var _this = _super.call(this, api, domManager, queue, new StringInputCustomComponent_1.StringInputCustomComponent(parameter.id, {
            name: parameter.name,
            type: parameter.elementType,
            title: parameter.note,
            value: parameter.defval,
            order: parameter.order,
            max: parameter.max,
            delay: 1000,
        }, container, domManager, queue), parameter, editMode) || this;
        _this._component.parameterDefinition.update = _this.updateAsync.bind(_this);
        return _this;
    }
    return StringInputParameterComponent;
}(BaseParameterComponent_1.BaseParameterComponent));
exports.StringInputParameterComponent = StringInputParameterComponent;
