"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SettingsGroups = void 0;
;
/**
 * Settings groups enum.
 */
var SettingsGroups;
(function (SettingsGroups) {
    SettingsGroups[SettingsGroups["WORLD"] = 1] = "WORLD";
    SettingsGroups[SettingsGroups["LIGHTS"] = 2] = "LIGHTS";
    SettingsGroups[SettingsGroups["BACKGROUND"] = 3] = "BACKGROUND";
    SettingsGroups[SettingsGroups["CAMERA"] = 4] = "CAMERA";
})(SettingsGroups = exports.SettingsGroups || (exports.SettingsGroups = {}));
