"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResponseType = void 0;
/**
 * The response type enum
 */
var ResponseType;
(function (ResponseType) {
    ResponseType["Empty"] = "";
    ResponseType["ArrayBuffer"] = "arraybuffer";
    ResponseType["Text"] = "text";
    ResponseType["Blob"] = "blob";
    ResponseType["Document"] = "document";
    ResponseType["JSON"] = "json";
})(ResponseType = exports.ResponseType || (exports.ResponseType = {}));
