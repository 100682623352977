"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OBJLoaderInstance = void 0;
var MaterialParser_1 = require("../materials/MaterialParser");
var THREE = __importStar(require("../../externals/three"));
require('../../3d/loaders/MTLLoader');
require('../../3d/loaders/OBJLoader');
var OBJLoader = /** @class */ (function () {
    function OBJLoader() {
    }
    OBJLoader.prototype.loadGeometry = function (urlObj, urlMtl, path, texturepath, side, transforms, requestHeaders) {
        return __awaiter(this, void 0, void 0, function () {
            var materials, mtlLoader_1, options, objLoader;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        materials = null;
                        if (!(urlMtl && urlMtl.length > 0)) return [3 /*break*/, 2];
                        mtlLoader_1 = new THREE.MTLLoader();
                        mtlLoader_1.setCrossOrigin(true);
                        if (path)
                            mtlLoader_1.setPath(path);
                        if (texturepath)
                            mtlLoader_1.setTexturePath(texturepath);
                        options = {};
                        options.wrap = THREE.ClampToEdgeWrapping;
                        mtlLoader_1.setMaterialOptions(options);
                        return [4 /*yield*/, new Promise(function (resolve, reject) {
                                mtlLoader_1.load(urlMtl, function (m) {
                                    m.preload();
                                    if (m.hasOwnProperty('materialsInfo') && Object.keys(m.materialsInfo).length > 0) {
                                        resolve(m);
                                    }
                                    else {
                                        resolve();
                                    }
                                }, function () { }, function () { return resolve(); });
                            })];
                    case 1:
                        // create promise which resolves once we have the material data
                        materials = _a.sent();
                        _a.label = 2;
                    case 2:
                        objLoader = new THREE.OBJLoader();
                        if (materials)
                            objLoader.setMaterials(materials);
                        if (path)
                            objLoader.setPath(path);
                        // Promise that resolves when the all the ourput geometries and the corresponding materials are loaded
                        return [2 /*return*/, new Promise(function (resolve, reject) {
                                var outputGeometry = [];
                                var promises = [];
                                // load the obj
                                objLoader.load(urlObj, function (object) { return __awaiter(_this, void 0, void 0, function () {
                                    var _this = this;
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0:
                                                // sanity checks for transformations
                                                if (!Array.isArray(transforms) ||
                                                    transforms.length == 0 ||
                                                    !transforms.every(function (m) { return m && m.isMatrix4; })) {
                                                    transforms = [new THREE.Matrix4()];
                                                }
                                                // traverse all meshes to load and apply the corresponding materials
                                                object.traverse(function (obj) {
                                                    if (obj.geometry) {
                                                        // create promises the resolve once the material is loaded
                                                        promises.push(new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
                                                            var hasUV, material, definition_1, i, transforms_1, transforms_1_1, t, pathString, geometryDefinition;
                                                            var e_1, _a;
                                                            return __generator(this, function (_b) {
                                                                switch (_b.label) {
                                                                    case 0:
                                                                        hasUV = false;
                                                                        if (obj.geometry instanceof THREE.BufferGeometry) {
                                                                            if (obj.geometry.hasOwnProperty('attributes') && obj.geometry.attributes.hasOwnProperty('uv')) {
                                                                                hasUV = true;
                                                                            }
                                                                        }
                                                                        else {
                                                                            if (obj.geometry instanceof THREE.Geometry) {
                                                                                if (obj.geometry.faceVertexUvs.length > 0) {
                                                                                    hasUV = true;
                                                                                }
                                                                            }
                                                                        }
                                                                        material = null;
                                                                        if (!(materials && obj.hasOwnProperty('material'))) return [3 /*break*/, 2];
                                                                        definition_1 = {
                                                                            version: '2.0',
                                                                            side: side,
                                                                            color: obj.material.color,
                                                                            transparency: 1 - obj.material.opacity,
                                                                            bumpAmplitude: obj.material.bumpScale,
                                                                        };
                                                                        // phong material shininess to metalness roughness conversion
                                                                        if (obj.material.hasOwnProperty('shininess')) {
                                                                            definition_1.metalness = Math.min(1, ((obj.material.shininess - 30) / 90));
                                                                            definition_1.roughness = 1 - Math.min(1, ((obj.material.shininess - 30) / 90));
                                                                        }
                                                                        // all the map information that is previously stored is now converted
                                                                        ['', 'bump', 'normal', 'alpha'].forEach(function (attr) {
                                                                            var temp = attr;
                                                                            if (attr === '')
                                                                                temp = 'bitmap';
                                                                            if (attr === 'alpha')
                                                                                temp = 'transparency';
                                                                            temp += 'texture';
                                                                            attr += 'Map';
                                                                            if (attr === 'Map')
                                                                                attr = 'map';
                                                                            if (obj.material[attr] && obj.material[attr].href) {
                                                                                definition_1[temp] = {};
                                                                                definition_1[temp].href = obj.material[attr].href;
                                                                                definition_1[temp].wrapS = obj.material[attr].wrapS;
                                                                                definition_1[temp].wrapT = obj.material[attr].wrapT;
                                                                                definition_1[temp].offset = obj.material[attr].offset.toArray();
                                                                                definition_1[temp].repeat = obj.material[attr].repeat.toArray();
                                                                            }
                                                                        });
                                                                        return [4 /*yield*/, new MaterialParser_1.MaterialParser(requestHeaders).fromJSONMaterialObject(definition_1)];
                                                                    case 1:
                                                                        material = _b.sent();
                                                                        _b.label = 2;
                                                                    case 2:
                                                                        i = 0;
                                                                        try {
                                                                            for (transforms_1 = __values(transforms), transforms_1_1 = transforms_1.next(); !transforms_1_1.done; transforms_1_1 = transforms_1.next()) {
                                                                                t = transforms_1_1.value;
                                                                                pathString = 'transformation_' + i;
                                                                                geometryDefinition = {
                                                                                    path: pathString,
                                                                                    geometry: obj.geometry,
                                                                                    texCoord: hasUV,
                                                                                    initialMatrix: t,
                                                                                    type: 'mesh',
                                                                                };
                                                                                if (material)
                                                                                    geometryDefinition.material = material;
                                                                                outputGeometry.push(geometryDefinition);
                                                                                ++i;
                                                                            }
                                                                        }
                                                                        catch (e_1_1) { e_1 = { error: e_1_1 }; }
                                                                        finally {
                                                                            try {
                                                                                if (transforms_1_1 && !transforms_1_1.done && (_a = transforms_1.return)) _a.call(transforms_1);
                                                                            }
                                                                            finally { if (e_1) throw e_1.error; }
                                                                        }
                                                                        resolve();
                                                                        return [2 /*return*/];
                                                                }
                                                            });
                                                        }); }));
                                                    }
                                                });
                                                // once all promises are resolved, resolve with the geometry Data
                                                return [4 /*yield*/, Promise.all(promises)];
                                            case 1:
                                                // once all promises are resolved, resolve with the geometry Data
                                                _a.sent();
                                                resolve(outputGeometry);
                                                return [2 /*return*/];
                                        }
                                    });
                                }); });
                            })];
                }
            });
        });
    };
    return OBJLoader;
}());
exports.OBJLoaderInstance = new OBJLoader();
